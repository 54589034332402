export type StreamCenterRef = {
  stopStreams: () => void;
};

export type StreamVideoRef = {
  stopStream: () => void;
  getHeaderHeight: () => number;
  getRtc: () => RTCPeerConnection | null;
};

export type Participant = {
  avatar: string;
  id: string;
  name: string;
  role: string;
  sip: string;
};

export type FeedItemList = {
  [key: string]: FeedItem;
};

export type FeedItem = {
  id: string;
  title: string;
  link: string;
  description: string;
  mpn: string;
  google_product_category: string;
  product_type: string;
  price: string;
  sale_price: string;
  image_link: string;
  images?: string[];
  additional_image_link: string;
  condition: string;
  availability: string;
  age_group: string;
  color: string;
  size: string;
  group_id?: string;
  chatbot_id?: string;
  item_group_id?: string;
  feed_id: number;
  feed_url: string;
};

export type Message = {
  id: string;
  creationTimestamp: number;
  status: string;
  onWall: boolean;
  reason: string;
  sender: {
    id: string;
    name: string;
    avatar: string;
    role: string;
    sip: string;
  };
  content: string;
  mentions: any[];
};

export type MessageUpdate = {
  id: string;
  senderId: string;
  updateReason: string;
};

export const WEB_CLIENT_REACTIONS_IMAGES = {
  heart: "/odience/web_client/Heart.svg",
  sparkles: "/odience/web_client/Sparkles.svg",
  confetti: "/odience/web_client/Confetti.svg",
  fire: "/odience/web_client/Fire.svg",
  wow: "/odience/web_client/Shocked.svg",
  smirk: "/odience/web_client/Sneaky_Face.svg",
  joy: "/odience/web_client/Smiley_Face.svg",
  kiss: "/odience/web_client/Kiss_Face.svg",
};

export type UserReactionInfo = {
  avatar: string;
  reaction: string;
  sip: string;
};

export type UserWallReactionsData = {
  users: UserReactionInfo[];
  level: number;
  total: number;
  timestamp: number;
};

export const abbrNum = (number: number, decPlaces: number): string => {
  decPlaces = Math.pow(10, decPlaces);

  const abbrev = ["k", "m", "b", "t"];

  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;

      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      return number.toString() + abbrev[i];
    }
  }

  return number.toString();
};

export type StreamUrl = {
  id: number;
  url: string;
  url2: string;
  serverId: string;
  download_url: string;
  resolution: string;
  status: string;
};

export type StreamInfo = {
  id: string;
  name: string;
  urls: StreamUrl[];
  is_equirectangular: boolean;
  is_stereo: boolean;
  recorded_type: number;
  recorded_label: string;
  saturation_mod: number;
  gamma_mod: number;
  active: boolean;
  rotation: number;
  is_360: boolean;
  pre_stream: string | null;
  type: string;
  mobile: boolean;
  loop: boolean;
  format: string;
  preview_url: string | null;
  status: string;
  video_length: number;
  code: string;
  chatbot_id: string;
  chatbot_payload: string;
  access_type: string;
  updated_at: number;
  selected: boolean;
  position: string;
};

export type Stream = {
  id: string;
  selected: boolean;
  info: StreamInfo;
  users: any[];
  order: number;
};

export type StreamsList = {
  list: Stream[];
  settings: MediaSettings;
};

type MediaSettings = {
  selected_stream_id: string;
  live_stream_switching: boolean;
  picture_in_picture_mode: boolean;
  picture_in_picture_params: {
    streamId: string;
    position: string;
  };
  silent_mode: boolean;
  media_pool: {
    event: {
      value: boolean;
      info: Record<string, unknown>;
    };
    videowall: {
      value: boolean;
      info: Record<string, unknown>;
    };
    content: {
      url: string;
      info: Record<string, unknown>;
    };
  };
  standby_media: {
    url: string;
    type: string;
  };
};

export const TextErrorLoading = ({ counter }: { counter?: number }) =>
  counter !== undefined && (
    <>Server is booting up... automatic refresh in {counter} seconds.</>
  );
export type CarouselOrientationType = "horizontal" | "vertical";

export type StreamInfosType = {
  id: string;
  serverId: string;
  type: typeof STREAM_TYPE_MAIN | typeof STREAM_TYPE_PIP;
  is360Stream: boolean;
  isRunning?: boolean;
  position?: "tl" | "tr" | "bl" | "br";
};
export type SelectedStreamsType = {
  main: StreamInfosType;
  pip?: StreamInfosType;
};
export type MediaPool = {
  event: {
    value: boolean;
    info: {
      fullscreen: string;
      inside_360: string;
      viewer_controls: string;
    };
  };
  videowall: {
    value: boolean;
    info: {
      fullscreen: string;
    };
  };
  content: {
    original_media: {
      id: number;
      slot: number;
      media_type: string;
      name: string;
      media_url: string;
      preview_url: string;
      event_id: number;
      group_id: number;
      date: string | null;
      formatted_url: string;
    };
    url: string;
    info: {
      type: string;
    };
  };
};

export const STREAM_TYPE_PIP = "in-picture";
export const STREAM_TYPE_MAIN = "main";

export const hideStreamSelection = (boolHide: boolean) => {
  const streamInput = document.querySelector("#streamInput");
  const streamIcon = document.querySelector("#streamIcon");
  if (boolHide) {
    streamInput?.classList.remove("show");
    streamIcon?.classList.remove("show");
  } else {
    streamInput?.classList.add("show");
    streamIcon?.classList.add("show");
  }
};

export const handleUpdateLiveStreamSwitching = (
  data: { value: boolean },
  hideStreamSelection: (boolHide: boolean) => void
) => {
  hideStreamSelection(data.value);
};

export const handleMessagesList = (
  messages: { list: Message[] },
  setMessagesList: (value: Message[]) => void,
  setBoolScroll: (value: boolean) => void
) => {
  setBoolScroll(true);
  setMessagesList(messages.list.slice().reverse());
  setTimeout(() => {
    setBoolScroll(false);
  }, 100);
};
