import { colors } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { useAtomValue } from "jotai";

type VideoWallRequestProps = {
  profileComplete: boolean;
  onActionToParticipate: VoidFunction;
  onActionToAccept: VoidFunction;
  onActionToDecline: VoidFunction;
  type: "videoWall" | "frontRow";
};

const VideoWallRequest = ({
  profileComplete,
  onActionToParticipate,
  onActionToAccept,
  onActionToDecline,
  type,
}: VideoWallRequestProps) => {
  const isFeaturedCaller = useAtomValue(atoms.odience.featuredCaller);
  const text = isFeaturedCaller ? (
    "You've been invited to join the video wall as a featured caller"
  ) : type === "videoWall" ? (
    "You've been invited to join the video wall"
  ) : (
    <p>
      Want to be part of the event?
      <br />
      Accept this invite for a surprise!
    </p>
  );

  return (
    <div css={videoWallDivStyle}>
      <div css={videoWallColStyle}>
        <div css={videoWallRowStyle}>
          {profileComplete && <span css={videoWallTextStyle}>{text}</span>}
          {!profileComplete && (
            <button css={closeButtonStyle} onClick={onActionToDecline}>
              <CloseIcon css={closeIconStyle} />
            </button>
          )}
        </div>

        <div css={videoWallButtonRowStyle}>
          {profileComplete ? (
            <>
              <button
                onClick={onActionToDecline}
                css={[
                  videoWallButton,
                  { backgroundColor: colors.primaryAccentColor },
                ]}
              >
                Dismiss
              </button>
              <button
                onClick={onActionToAccept}
                css={[
                  videoWallButton,
                  { backgroundColor: colors.secondaryAccentColor },
                ]}
              >
                Accept
              </button>
            </>
          ) : (
            <div css={enterDisplayNameStyle} onClick={onActionToParticipate}>
              Enter Display Name to participate
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoWallRequest;

const videoWallButton = css({
  width: "8em",
  height: "3em",
  userSelect: "none",
  borderRadius: "10px",
});

const videoWallRowStyle = css({
  display: "flex",
  gap: "0.6em",
  alignItems: "center",
  justifyContent: "center",
});

const videoWallTextStyle = css({
  fontSize: "1.1rem",
  fontWeight: "bold",
});

const videoWallDivStyle = css({
  position: "absolute",
  color: colors.primaryTextColor,
  bottom: "50%",
  transform: "translate(-50%, 50%)",
  display: "flex",
  zIndex: 115,
  padding: "1.25em",
  backgroundColor: "rgba(20, 20, 20, 0.7)",
  borderRadius: "10px",
  transition: "opacity 0.5s ease",
  opacity: 1,
  border: `0.5px solid ${colors.secondaryAccentColor}`,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const videoWallColStyle = css({
  display: "flex",
  gap: "1em",
  flexDirection: "column",
  width: "100%",
});

const videoWallButtonRowStyle = css({
  display: "flex",
  gap: "1vw",
  alignItems: "end",
  justifyContent: "space-between",
  width: "100%",
});

const enterDisplayNameStyle = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  padding: "2vh 1vw",
  color: colors.primaryTextColor,
  backgroundColor: colors.tertiaryBackground,
  fontSize: "1em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "13em",
  cursor: "pointer",
});

const closeButtonStyle = css({
  display: "flex",
  alignItems: "top",
  justifyContent: "top",
});

const closeIconStyle = css({ width: "1.5em", height: "1.5em" });
