import Planar from "@/assets/Planar";
import playButtonAnimation from "@/assets/lottie/PlayButton.json";
import LoadingAnimation from "@/components/odience/components/LoadingAnimation";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import Slider from "@/components/shared/Slider";
import { colors } from "@/styles/global.styles";
import { signalingServerUrl } from "@/utils";
import { generateRandomString, isMobile } from "@/utils/helpers/Utils";
import { atoms } from "@/utils/helpers/atoms";
import { getLocalOdienceUser } from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import {
  getSwipeToExplorePrompt,
  setSwipeToExplorePrompt,
} from "@/utils/helpers/sessionStorage";
import { useToast } from "@/utils/helpers/toastManager";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { useExponentialBackoff } from "@/utils/hooks/useExponentialBackoff";
import { useKeyDown } from "@/utils/hooks/useKeydown";
import { isWebSocketConnected } from "@/utils/network";
import { css } from "@emotion/react";
import { useNetworkState } from "@uidotdev/usehooks";
import { useAtom, useAtomValue } from "jotai";
import Lottie from "lottie-react";
import React, {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useUnmount } from "usehooks-ts";
import DragAnimation from "../../../../../assets/lottie/ClickAndDrag3.json";
import {
  activeStreamContainerStyle,
  customCursorStyle,
  fullScreenButtonStyle,
  fullScreenDivStyle,
  longPressAnimStyle,
  longPressAnimVisibleStyle,
  startStreamButtonStyle,
  startStreamDivStyle,
  streamErrorButtonStyle,
  streamErrorDivStyle,
} from "../EventStream.style";
import {
  FeedItemList,
  MediaPool,
  STREAM_TYPE_MAIN,
  StreamInfosType,
  StreamVideoRef,
  TextErrorLoading,
} from "../helpers/EventStreamUtils";
import MediaPoolOverlay from "./MediaPool";
import StreamController, {
  StreamControllerCallbacks,
} from "./StreamController";
type StreamVideoProps = {
  selectedStream: StreamInfosType;
  event?: OdienceEvent;
  isLandscape?: boolean;
  errorLoading?: boolean;
  onErrorLoading?: () => void;
  standbyImage?: string;
  streamDefaultRotation: number;
  onStreamActive?: (active: boolean) => void;
  extraMargin?: StreamVideoExtraMarginType;
  onFullScreen?: (fullScreen: boolean) => void;
  children?: React.ReactElement<StreamVideoProps> | ReactNode;
  isParentFullScreen?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isMouseHover: boolean;
  mediaPool?: MediaPool | null;
  feedItems?: FeedItemList;
  selectMiniItem?: (id: string) => void;
  onOpenFeedModal?: (itemId: string) => void;
  lastVolume?: number;
  setLastVolume?: (value: number) => void;
};
const STREAM_VIDEO_WITH_POSITION_DEFAULT_RATIO = 16 / 9;
export type StreamVideoExtraMarginType = {
  bottomLeftBottom?: number;
  bottomLeftRight?: number;
  topRight?: number;
};
const streamMutedImage = "/odience/web_client/Mute.svg"; // stream is muted  - Volume 0
const streamLowVolumeImage = "/odience/web_client/LowVolume.svg"; // low volume on stream - Volume 1-70
const streamHighVolumeImage = "/odience/web_client/HighVolume.svg"; // high volume on stream - Volume 71-100
const StreamVideo = ({
  ref,
  selectedStream,
  event,
  isLandscape,
  errorLoading,
  onErrorLoading,
  standbyImage,
  streamDefaultRotation,
  onStreamActive,
  extraMargin,
  onFullScreen,
  children,
  isParentFullScreen,
  onMouseEnter,
  onMouseLeave,
  isMouseHover,
  mediaPool,
  feedItems,
  selectMiniItem,
  onOpenFeedModal,
  lastVolume,
  setLastVolume,
}: StreamVideoProps & {
  ref?: React.RefObject<StreamVideoRef | null>;
}) => {
  const { showToast, dismissToast } = useToast();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [playStream, setPlayStream] = useState(false);
  const [isStreamActive, setIsStreamActive] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [countdown, setCountdown] = useState<number | undefined>(undefined);
  const [countdownFinished, setCountdownFinished] = useState<boolean>(false);
  const isVideoWallCall = useAtomValue(atoms.odience.isVideoWallCall);
  const isFrontRowCall = useAtomValue(atoms.odience.isFrontRowCall);
  const isFeaturedCaller = useAtomValue(atoms.odience.featuredCaller);
  const mutedByModerator = useAtomValue(atoms.odience.mutedByModerator);
  const [streamVolume, setStreamVolume] = useAtom(atoms.odience.streamVolume);
  const [isMuted, setIsMuted] = useState(false);

  const currentReceiveId = useRef("");
  const rtc = useRef<RTCPeerConnection | null>(null);
  const dc = useRef<RTCDataChannel | null>(null);
  const noStream = useRef<boolean>(false);
  const showError = useRef<number | undefined>(undefined);
  const websocketRef = useRef<WebSocket | null>(null);
  const isPlayingRef = useRef(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const longPressAnimRef = useRef<HTMLDivElement>(null);
  const mediaElementRef = useRef<HTMLDivElement>(null);
  const secondsToShowErrorLoadingCountdown = 30;
  const secondsToFallbackIfNoResponse = 5;
  const secondsToReloadIfNoStream = 30;
  const streamController = useRef<StreamController | null>(null);
  const streamRecenterRef = useRef<HTMLButtonElement>(null);
  const [showStreamRecenter, setShowStreamRecenter] = useState(false);
  const [isEnabledStreamRecenter, setIsEnabledStreamRecenter] = useState(false);
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [streamVolumeImage, setStreamVolumeImage] = useState(
    streamHighVolumeImage
  );
  const [callActiveUserAdjustedVolume, setCallActiveUserAdjustedVolume] =
    useState(false);
  const callActive = useAtomValue(atoms.calling.callActive);
  const divHeaderButtonRef = useRef<HTMLDivElement>(null);
  const isParentFulScreenPrevious = useRef(isParentFullScreen);
  const network = useNetworkState();
  const localOdienceUser = getLocalOdienceUser();
  const localOdienceMsisdn = localOdienceUser
    ? JSON.parse(localOdienceUser).msisdn
    : null;

  const [isMediaPoolOpen, setIsMediaPoolOpen] = useState(
    mediaPool?.event.value
  );
  const [mediaPoolConfirmation, setMediaPoolConfirmation] = useState(false);
  const [isTutorialOverlayVisible, setIsTutorialOverlayVisible] =
    useState(true);

  const handleTutorialOverlayClick = () => {
    setIsTutorialOverlayVisible(false);
    setSwipeToExplorePrompt("1");
  };
  useKeyDown((event: KeyboardEvent) => {
    if (selectedStream.isRunning) {
      if (
        event.key === "ArrowUp" ||
        event.key === "ArrowDown" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight"
      ) {
        handleTutorialOverlayClick();
      }
    }
  });

  const handleCloseMediaPool = () => {
    if (mediaPool?.event) {
      setMediaPoolConfirmation(true);
    }
  };
  const confirmationMessage =
    "Are you sure you would like to close the media pool?";

  // Stream is always muted when on a front row session or on video wall with local mic activated, Or mute stream when media pool is a video and open/active
  const disableStreamSound =
    ((isFrontRowCall || (isVideoWallCall && mutedByModerator)) && callActive) ||
    (isMediaPoolOpen &&
      mediaPool?.content?.original_media?.media_type === "url");

  useEffect(() => {
    if (!videoRef.current || selectedStream.type !== STREAM_TYPE_MAIN) return;

    const video = videoRef.current;

    if (disableStreamSound) {
      if (!isMuted) {
        toggleIsMuted();
      }
      setStreamVolume(0);
      setStreamVolumeIcon(0);
      video.volume = 0;
      return;
    }

    const targetVolume = isMuted ? 0 : lastVolume || 1;
    video.volume = targetVolume;
    setStreamVolume(targetVolume * 100);
    setStreamVolumeIcon(targetVolume * 100);

    if (callActive) {
      const maxVolumeFeaturedCaller = 0.3;
      const maxVolumeVirtualSpectator = 0.7;
      if (
        isFeaturedCaller &&
        video.volume > maxVolumeFeaturedCaller &&
        !callActiveUserAdjustedVolume
      ) {
        setCallActiveUserAdjustedVolume(true);
        setStreamVolume(maxVolumeFeaturedCaller * 100);
        video.volume = maxVolumeFeaturedCaller;
      } else if (
        video.volume > maxVolumeVirtualSpectator &&
        !callActiveUserAdjustedVolume
      ) {
        setCallActiveUserAdjustedVolume(true);
        setStreamVolume(maxVolumeVirtualSpectator * 100);
        video.volume = maxVolumeVirtualSpectator;
      }
    } else {
      setCallActiveUserAdjustedVolume(false);
    }
  }, [
    disableStreamSound,
    isVideoWallCall,
    isFrontRowCall,
    isFeaturedCaller,
    mutedByModerator,
    callActive,
    isMediaPoolOpen,
    selectedStream,
    lastVolume,
    isMuted,
  ]);

  useEffect(() => {
    setStreamVolumeIcon(streamVolume);
  }, [streamVolume, callActive]);

  const toggleIsMuted = () => {
    setIsMuted(!isMuted);
  };

  const handleMuteStream = () => {
    if (disableStreamSound || !videoRef.current) return;

    const video = videoRef.current;

    if (!isMuted && setLastVolume) {
      toggleIsMuted();
      setLastVolume(streamVolume / 100);
      setStreamVolume(0);
      video.volume = 0;
    } else {
      // Unmute and restore the previous volume
      const volumeToRestore = lastVolume || 1;
      toggleIsMuted();
      setStreamVolume(volumeToRestore * 100);
      video.volume = volumeToRestore;
    }
  };

  const setStreamVolumeIcon = (volume: number) => {
    if (volume === 0) {
      setStreamVolumeImage(streamMutedImage);
    } else if (volume > 0 && volume <= 70) {
      setStreamVolumeImage(streamLowVolumeImage);
    } else {
      setStreamVolumeImage(streamHighVolumeImage);
    }
  };

  useEffect(() => {
    isParentFulScreenPrevious.current = isParentFullScreen;
  }, [isParentFullScreen]);

  useImperativeHandle(ref, () => ({
    stopStream: disconnect,
    getRtc: () => {
      return rtc.current;
    },
    getHeaderHeight: () => {
      const rect = divHeaderButtonRef.current?.getBoundingClientRect();
      if (!rect || !divHeaderButtonRef.current) {
        return 0;
      }
      const computedStyle = getComputedStyle(divHeaderButtonRef.current);
      const top = parseInt(computedStyle.top);
      return (rect?.height || 0) + top;
    },
  }));

  useEffect(() => {
    if (errorLoading) {
      setCountdown(secondsToShowErrorLoadingCountdown);
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown !== undefined && prevCountdown <= 1) {
            clearInterval(countdownInterval);
            setCountdownFinished(true);
            window.location.reload();
            return undefined;
          }
          return prevCountdown! - 1;
        });
      }, 1000);
      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [errorLoading]);

  useEffect(() => {
    if (playStream || isStreamActive) {
      dismissErrorLoading();
    }
  }, [playStream, isStreamActive]);

  const dismissErrorLoading = () => {
    setCountdown(undefined);
    setCountdownFinished(false);
    if (errorLoading) {
      onErrorLoading?.();
    }
  };
  const toggleFullScreen = () => {
    dismissToast?.();
    setIsFullScreen(!isFullScreen);
    onFullScreen?.(!isFullScreen);
  };
  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  const sendStreamRequest = () => {
    if (!isWebSocketConnected(websocketRef)) {
      return;
    }
    const requestPayload = {
      id: selectedStream.serverId,
      type: "streamRequest",
      rotation: streamDefaultRotation - 180,
      receiver: currentReceiveId.current,
    };
    console.log(requestPayload);
    websocketRef.current.send(JSON.stringify(requestPayload));
    setTimerForNoStream();
  };
  useEffect(() => {
    if (!selectedStream) {
      return;
    }

    if (!network.online) {
      setIsLoading(true);
      return;
    }

    if (!selectedStream.isRunning) {
      setIsLoading(false);
      return;
    }
    const longPressAnim = longPressAnimRef.current;
    const mediaElement = mediaElementRef.current;
    const hasMouseInteractions =
      selectedStream.type === STREAM_TYPE_MAIN && mediaElement && longPressAnim;
    if (hasMouseInteractions) {
      mediaElement.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
    }
    connectSocket();
    return () => {
      if (hasMouseInteractions) {
        mediaElement.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
      }
      disconnect();
      stopReconnectSocketRetry();
      streamController.current?.close();
      streamController.current = null;
    };
  }, [selectedStream.serverId, selectedStream.isRunning, network.online]);

  useUnmount(() => {
    disconnect();
    stopReconnectSocketRetry();
  });

  const connectSocket = () => {
    if (!network.online) {
      return;
    }

    disconnect();
    const receiveID = generateRandomString(10);
    currentReceiveId.current = receiveID;
    websocketRef.current = new WebSocket(
      `wss://${signalingServerUrl}/${receiveID}`
    );
    // Dismiss current states and video source
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    dismissErrorLoading();
    setIsLoading(true);
    setPlayStream(false);
    websocketRef.current.onopen = handleWebSocketOpen;
    websocketRef.current.onmessage = handleWebSocketMessage;
    websocketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      reconnectSocket();
    };
  };

  const { run: reconnectSocket, stop: stopReconnectSocketRetry } =
    useExponentialBackoff(connectSocket, "infinite");

  useEffect(() => {
    setIsExpanded(false);
  }, [selectedStream.position, isParentFullScreen]);

  const startStream = () => {
    const videoTag = videoRef.current;
    setIsLoading(false);
    setPlayStream(false);
    setFirstLoad(false);
    setIsStreamActive(true);
    onStreamActive?.(true);
    if (videoTag) {
      void videoTag.play();
      if (selectedStream.type !== STREAM_TYPE_MAIN) {
        videoTag.volume = 0;
      }
    }
    isPlayingRef.current = true;
    if (selectedStream.type === STREAM_TYPE_MAIN && dc.current) {
      streamController.current = new StreamController(
        selectedStream.serverId,
        currentReceiveId.current,
        dc.current,
        streamControllerCallbacks,
        selectedStream.serverId
      );
    }
  };
  const streamControllerCallbacks: StreamControllerCallbacks = {
    onDrag() {
      if (!selectedStream.is360Stream) {
        return;
      }
      showRecenter(true);
    },
  };

  const disconnect = () => {
    closeStream();
    closeSocket();
  };

  const closeStream = () => {
    console.log(`Closing rtcp connection for ${selectedStream}`);
    dc.current?.close();
    dc.current = null;
    clearTimeout(showError.current);
    if (rtc.current && rtc.current.connectionState !== "closed") {
      try {
        for (const transceiver of rtc.current.getTransceivers())
          transceiver.stop?.();
      } catch (e) {
        console.error(e);
      }
      try {
        for (const sender of rtc.current.getSenders()) sender.track?.stop();
      } catch (e) {
        console.error(e);
      }
    }
    rtc.current?.close();
    rtc.current = null;
  };

  const closeSocket = () => {
    console.log(`Closing and stopping socket for ${selectedStream}`);
    try {
      if (isWebSocketConnected(websocketRef)) {
        websocketRef.current.send(
          JSON.stringify({
            id: selectedStream.serverId,
            type: "stop",
            receiver: currentReceiveId.current,
          })
        );

        websocketRef.current.close();
      }
    } catch (e) {
      console.error(e);
    }
    websocketRef.current = null;
  };
  const handleOffer = async (offer: RTCSessionDescriptionInit) => {
    rtc.current = createPeerConnection(offer.sdp);
    await rtc.current.setRemoteDescription(offer);
    await sendAnswer();
  };
  const handleRecenterStream = () => {
    if (isEnabledStreamRecenter && streamController.current) {
      void streamController.current.recenter();
      showRecenter(false);
    }
  };
  const showRecenter = (show: boolean) => {
    setShowStreamRecenter(show);
    setIsEnabledStreamRecenter(show);
  };
  const sendAnswer = async () => {
    try {
      if (!rtc.current) {
        return;
      }
      const answer = await rtc.current.createAnswer();
      await rtc.current.setLocalDescription(answer);
      await waitForIceGathering();
      if (!isWebSocketConnected(websocketRef)) {
        return;
      }
      websocketRef.current.send(
        JSON.stringify({
          id: selectedStream.serverId,
          type: rtc.current.localDescription!.type,
          sdp: rtc.current.localDescription!.sdp,
        })
      );
    } catch (e) {
      console.error(e);
    }
  };
  const createPeerConnection = (sdp?: string) => {
    let config: RTCConfiguration = {
      bundlePolicy: "max-bundle",
    };
    if (sdp) {
      const matchIpAddressForSTUN = sdp.match(
        /a=candidate:\d+ \d+ \w+ \d+ (\d{1,3}(?:\.\d{1,3}){3}) \d+ typ srflx/
      );
      if (matchIpAddressForSTUN && matchIpAddressForSTUN.length >= 1) {
        const turn_url = `turn:${matchIpAddressForSTUN[1]}:3478`;
        const iceServers = [
          {
            urls: turn_url,
            username: "safa1",
            credential: "nexosims",
            realm: "odience.com",
          },
        ];
        config = {
          ...config,
          iceServers,
          iceTransportPolicy: "all",
        };
      }
    }
    const pc = new RTCPeerConnection(config);
    pc.addEventListener("icegatheringstatechange", () => {}, false);
    pc.addEventListener("iceconnectionstatechange", () => {}, false);
    pc.addEventListener("signalingstatechange", () => {}, false);
    pc.addEventListener("connectionstatechange", () => {
      console.log("onconnectionstatechange ", pc.connectionState);

      // Auto reconnect to the websocket and stream
      if (
        pc.connectionState === "failed" ||
        pc.connectionState === "disconnected"
      ) {
        reconnectSocket();
      }
    });
    const videoTag = videoRef.current;
    if (videoTag) {
      videoTag.onloadedmetadata = () => {
        if (videoTag.srcObject !== null) {
          setIsLoading(false);
          if (
            firstLoad &&
            !isPlayingRef.current &&
            selectedStream.type === STREAM_TYPE_MAIN
          ) {
            setPlayStream(true);
            setIsStreamActive(false);
            onStreamActive?.(false);
          } else {
            startStream();
          }
        }
      };
    }
    pc.addEventListener("track", (evt) => {
      const videoTag = videoRef.current;
      if (videoTag) {
        videoTag.srcObject = evt.streams[0];
      }
      if (evt.streams.length === 0) {
        onErrorLoading?.();
        setIsLoading(false);
        setPlayStream(false);
      }
    });
    pc.ondatachannel = (event) => {
      dc.current = event.channel;
      dc.current.onopen = () => {};
      if (onDataChannelMessage && dc.current) {
        dc.current.onmessage = onDataChannelMessage;
      }
    };
    return pc;
  };
  const waitForIceGathering = () => {
    return new Promise<void>((resolve) => {
      if (!rtc.current) {
        return;
      }
      if (rtc.current.iceGatheringState === "complete") {
        resolve();
      } else {
        const checkState = () => {
          if (rtc.current?.iceGatheringState === "complete") {
            rtc.current.removeEventListener(
              "icegatheringstatechange",
              checkState
            );
            resolve();
          }
        };
        rtc.current.addEventListener("icegatheringstatechange", checkState);
      }
    });
  };
  const handleMouseDown = (event: MouseEvent) => {
    const longPressAnim = longPressAnimRef.current;
    if (event.button === 0 && longPressAnim) {
      longPressAnim.style.left = `${event.clientX - longPressAnim.offsetWidth / 2}px`;
      longPressAnim.style.top = `${event.clientY - longPressAnim.offsetHeight / 2}px`;
      longPressAnim.classList.add(longPressAnimVisibleStyle.name);
      document.body.classList.add(customCursorStyle.name);
    }
  };
  const handleMouseUp = (event: MouseEvent) => {
    const longPressAnim = longPressAnimRef.current;
    if (event.button === 0 && longPressAnim) {
      longPressAnim.style.display = "none";
      document.body.style.cursor = "default";
    }
  };
  const handleMouseMove = () => {
    const longPressAnim = longPressAnimRef.current;
    if (longPressAnim) {
      longPressAnim.style.display = "none";
    }
  };
  const handleWebSocketOpen = () => {
    stopReconnectSocketRetry();
    setTimerForNoResponse();
    setIsLoading(true);
    sendStreamRequest();
  };
  const setTimerForNoStream = () => {
    noStream.current = false;
    setTimeout(() => {
      if (showError.current) {
        clearTimeout(showError.current);
        showError.current = undefined;
        noStream.current = true;
        setIsLoading(false);
      }
    }, secondsToFallbackIfNoResponse * 1000);
  };
  const setTimerForNoResponse = () => {
    clearTimeout(showError.current);
    showError.current = +setTimeout(() => {
      onErrorLoading?.();
      setIsLoading(false);
    }, secondsToReloadIfNoStream * 1000);
  };
  const handleWebSocketMessage = async (evt: MessageEvent) => {
    const received_msg = evt.data;
    const object = JSON.parse(received_msg);
    if (object.type === "offer") {
      clearTimeout(showError.current);
      showError.current = undefined;
      await handleOffer(object);
    }
    if (object.type === "full") {
      // Handle stream full case
    }
    if (object.type === "comeback") {
      onErrorLoading?.();
      setCountdown(60);
    }
    if (object.type === "ping") {
      if (!isWebSocketConnected(websocketRef)) {
        return;
      }
      const requestPayload = {
        id: currentReceiveId.current,
        type: "pong-web",
        receiver: currentReceiveId.current,
        ...(localOdienceMsisdn && { pn: localOdienceMsisdn }),
      };
      websocketRef.current.send(JSON.stringify(requestPayload));
    }
  };
  const streamErrorDiv = (
    <div css={streamErrorDivStyle}>
      {!countdownFinished ? (
        <div>
          <TextErrorLoading counter={countdown} />
        </div>
      ) : (
        <>
          <span>
            Stream unavailable. Please refresh your page or try again later.
          </span>
          <button
            type="button"
            css={streamErrorButtonStyle}
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </button>
        </>
      )}
      {children}
    </div>
  );
  const startStreamDiv = (
    <div css={startStreamDivStyle}>
      <div
        css={{
          color: colors.primaryTextColor,
          letterSpacing: "1px",
          fontSize: "1rem",
        }}
      >
        Press Play To Start The Stream
      </div>
      <button type="button" onClick={startStream} css={startStreamButtonStyle}>
        <Lottie
          id="playButtonAnimation"
          css={{
            bottom: "20px",
            height: "200px",
            position: "relative",
            width: "200px",
          }}
          animationData={playButtonAnimation}
          loop={true}
        />
      </button>
      {children}
    </div>
  );

  const onDataChannelMessage = (evt: MessageEvent) => {
    const objData = JSON.parse(evt.data);
    const strQR = objData.qrcode;
    if (strQR !== "") {
      if (isProvisioned() && feedItems && selectMiniItem && onOpenFeedModal) {
        const keys = Object.keys(feedItems);
        if (keys.length > 0) {
          selectMiniItem(keys[0]);
          onOpenFeedModal(strQR);
        } else {
          dismissToast?.();
          showToast(
            "QR code not detected.",
            "top-center",
            "1em",
            mediaElementRef
          );
        }
      } else {
        dismissToast?.();
        showToast(
          "For full access, get the Odience App",
          "top-center",
          "1em",
          mediaElementRef
        );
      }
    }
  };

  const handleOnAnimationEnd = () => {
    // Wait for the end of the animation transition to disable the stream center click
    if (!showStreamRecenter) {
      showRecenter(false);
    }
  };
  const handleMouseEnter = () => {
    setIsSliderVisible(true);
  };
  const handleMouseLeave = () => {
    setIsSliderVisible(false);
  };

  const handleStreamClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = event.target as HTMLElement;
    if (divHeaderButtonRef.current?.contains(targetElement)) {
      return;
    }
    if (
      !selectedStream.is360Stream &&
      selectedStream.type === STREAM_TYPE_MAIN
    ) {
      dismissToast?.();
      showToast(
        "Drag disabled",
        "top-center",
        "1em",
        isFullScreen ? undefined : mediaElementRef,
        toastIcon()
      );
    }
  };
  const toastIcon = () => {
    return (
      <div
        css={{
          display: "flex",
          alignItems: "center",
          color: colors.secondaryTextColor,
          gap: "0.3em",
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Planar css={{ fill: colors.secondaryTextColor, flexShrink: 0 }} />
        <span>2D Stream</span>
      </div>
    );
  };
  const canExpand = !isMobile() && selectedStream.position;
  const activeStream = (
    <div
      id={selectedStream.serverId}
      ref={mediaElementRef}
      css={{
        backgroundColor: isMobile() ? "#111111" : "#000e1a",
        position: isFullScreen ? "fixed" : "relative",
        height: isMobile() && !isLandscape ? "50vh" : "100%",
        width: isFullScreen ? "100vw" : "100%",
        userSelect: "none",
        top: isFullScreen ? 0 : undefined,
        left: isFullScreen ? 0 : undefined,
        visibility: isStreamActive ? "visible" : "hidden",
        ...(isMouseHover && { ".controlButton": { display: "block" } }),
        ...(canExpand &&
          isMouseHover && {
            ".controlButtonExpand": { display: "flex" },
          }),
        zIndex: isFullScreen ? 102 : 1,
        boxShadow: selectedStream.position
          ? "0 0 10px rgba(0, 0, 0, 0.5)"
          : "undefined",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleStreamClick}
    >
      {/* Add a gradient on top of the main stream */}
      {selectedStream.type === STREAM_TYPE_MAIN && (
        <div
          style={{
            position: "absolute",
            background:
              "transparent linear-gradient(180deg, #11111100 0%, #111111 100%) 0% 0% no-repeat padding-box",
            opacity: "0.33",
            width: "100%",
            height: "10%",
            transform: "matrix(-1, 0, 0, -1, 0, 0)",
          }}
        />
      )}
      {selectedStream.type === STREAM_TYPE_MAIN && (
        <div ref={longPressAnimRef} css={longPressAnimStyle} />
      )}

      {mediaPool && !playStream && !isLoading && (
        <MediaPoolOverlay
          mediaPool={mediaPool}
          isOpen={isMediaPoolOpen}
          handleClose={handleCloseMediaPool}
        />
      )}
      {/* Tutorial Overlay that covers the video initially if stream is 360 */}
      {!isMobile() &&
        isTutorialOverlayVisible &&
        selectedStream.is360Stream &&
        !getSwipeToExplorePrompt() && (
          <div
            onClick={handleTutorialOverlayClick}
            css={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 9999,
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span
              css={{
                color: colors.primaryTextColor,
                fontSize: "1.5rem",
                marginBottom: "-3vh",
                textAlign: "center",
              }}
            >
              Click and drag to explore!
            </span>
            <Lottie
              css={{ height: "20vh", minHeight: "150px", maxHeight: "225px" }}
              animationData={DragAnimation}
              loop={true}
            />
          </div>
        )}
      {!isMobile() && selectedStream.type === STREAM_TYPE_MAIN && (
        <div css={fullScreenDivStyle} ref={divHeaderButtonRef}>
          <div
            css={{
              display: "flex",
              gap: "1vw",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {!disableStreamSound && isSliderVisible && (
              <Slider
                initialValue={streamVolume}
                toggleIsMuted={toggleIsMuted}
                isMuted={isMuted}
              />
            )}
            <button
              type="button"
              ref={streamRecenterRef}
              onClick={handleMuteStream}
              css={fullScreenButtonStyle}
              className="controlButton"
              style={{
                opacity: streamVolume > 0 ? 1 : 0.5,
                transition: "opacity 0.5s ease-in-out",
                width: "2.5em",
                height: "2.5em",
                ...(disableStreamSound && {
                  opacity: 0.5,
                  cursor: "default",
                }),
              }}
              onTransitionEnd={handleOnAnimationEnd}
            >
              <img src={streamVolumeImage} alt="Stream Volume" />
            </button>
          </div>
          {selectedStream.is360Stream && (
            <button
              type="button"
              ref={streamRecenterRef}
              onClick={handleRecenterStream}
              css={fullScreenButtonStyle}
              className="controlButton"
              style={{
                opacity: showStreamRecenter ? 1 : 0.5,
                transition: "opacity 0.5s ease-in-out",
                cursor: isEnabledStreamRecenter ? "pointer" : "inherit",
                width: "2.5em",
                height: "2.5em",
              }}
              onTransitionEnd={handleOnAnimationEnd}
            >
              <img
                src={"/odience/web_client/recenter.svg"}
                alt="Center stream"
              />
            </button>
          )}
          <button
            type="button"
            onClick={toggleFullScreen}
            className="controlButton"
            css={fullScreenButtonStyle}
          >
            <img
              src={
                isFullScreen
                  ? "/odience/web_client/Contract_fullscreen.svg"
                  : "/odience/web_client/Expand_fullscreen.svg"
              }
              alt="Full Screen"
            />
          </button>
        </div>
      )}
      {canExpand && (
        <div
          style={{
            position: "absolute",
            bottom:
              selectedStream.position === "tl" ||
              selectedStream.position === "tr"
                ? "0.2em"
                : "inherit",
            top:
              selectedStream.position === "bl" ||
              selectedStream.position === "br"
                ? "0.2em"
                : "inherit",
            right:
              selectedStream.position === "tl" ||
              selectedStream.position === "bl"
                ? "0.2em"
                : "inherit",
            left:
              selectedStream.position === "tr" ||
              selectedStream.position === "br"
                ? "0.2em"
                : "inherit",
            zIndex: "2",
          }}
          css={{ display: "none" }}
          className="controlButtonExpand"
        >
          <button
            type="button"
            style={{
              height: "1.5em",
              width: "1.5em",
              cursor: "pointer",
            }}
            onClick={toggleIsExpanded}
          >
            <img
              src={
                isExpanded
                  ? "/odience/web_client/Contract.svg"
                  : "/odience/web_client/Expand.svg"
              }
              alt="Expand"
            />
          </button>
        </div>
      )}
      <video
        ref={videoRef}
        crossOrigin="anonymous"
        id={`video-${selectedStream.serverId}`}
        playsInline
        css={activeStreamContainerStyle(
          selectedStream.type === STREAM_TYPE_MAIN
        )}
      />
      {children}
    </div>
  );
  const standbyScreen = (
    <div css={streamErrorDivStyle}>
      <img
        src={standbyImage}
        css={{ height: "100%", width: "100%", objectFit: "cover" }}
      />
      {children}
    </div>
  );
  const expandRadio = isExpanded ? 1.5 : 1;
  const position = selectedStream.position;
  const height = isMobile()
    ? undefined
    : position
      ? (isParentFullScreen ? 30 : 20) * expandRadio + "vh"
      : "100%";
  const width = position
    ? `calc(${height} * ${STREAM_VIDEO_WITH_POSITION_DEFAULT_RATIO})`
    : "100%";
  const margin = "0.5em";
  const wrapperCss = css({
    position: position ? "absolute" : "relative",
    height,
    width,
  });
  const wrapperCssPosition = position
    ? css({
        // On top right we need the video below the recenter/fullscreen icons
        top:
          position === "tr"
            ? `calc(${extraMargin?.topRight || 0}px + ${margin})`
            : position === "tl"
              ? margin
              : "undefined",
        bottom:
          position === "bl"
            ? `calc(${extraMargin?.bottomLeftBottom || 0}px + ${margin})`
            : position === "br"
              ? margin
              : "undefined",
        left:
          position === "bl"
            ? `calc(${extraMargin?.bottomLeftRight || 0}px + ${margin})`
            : position === "tl"
              ? margin
              : "undefined",
        right: position === "tr" || position === "br" ? margin : "undefined",
        // Only animate when not in transition for fullscreen mode
        transition:
          isParentFulScreenPrevious.current === isParentFullScreen
            ? "all 0.2s ease-in-out"
            : undefined,
        zIndex: 103,
      })
    : {};
  const loadingScreen = (
    <div style={{ width: "100%", height: "100%" }}>
      <LoadingAnimation event={event} />
      {children}
    </div>
  );
  return (
    <div key={selectedStream.serverId} css={[wrapperCss, wrapperCssPosition]}>
      {/* Only add loading and play logic to main stream*/}
      {selectedStream.type === STREAM_TYPE_MAIN && (
        <>
          {isLoading && !errorLoading && loadingScreen}
          {errorLoading && streamErrorDiv}
          {playStream && selectedStream.isRunning && startStreamDiv}
          {(!selectedStream.isRunning || noStream.current) &&
            !isLoading &&
            standbyScreen}
        </>
      )}
      {selectedStream.isRunning && activeStream}
      {mediaPoolConfirmation && (
        <ConfirmationPopup
          title="Close Media Pool"
          togglePopup={() => {
            setMediaPoolConfirmation(false);
          }}
          confirmationMessage={confirmationMessage}
          handleAction={() => {
            setIsMediaPoolOpen(false);
            setMediaPoolConfirmation(false);
          }}
          primaryButtonText="Confirm"
          closeButtonActive={true}
        />
      )}
    </div>
  );
};
export default StreamVideo;
