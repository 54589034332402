import { atoms } from "@/utils/helpers/atoms";
import { css } from "@emotion/react";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";

type SliderProps = {
  toggleIsMuted: () => void;
  isMuted: boolean;
  min?: number;
  max?: number;
  step?: number;
  initialValue?: number;
};

const Slider = ({
  min = 0,
  max = 100,
  step = 1,
  initialValue = 0,
  toggleIsMuted,
  isMuted,
}: SliderProps) => {
  const valueRef = useRef<number>(initialValue);
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [localValue, setLocalValue] = useState<number>(initialValue);
  const [streamVolume, setStreamVolume] = useAtom(atoms.odience.streamVolume);
  const isFeaturedCaller = useAtomValue(atoms.odience.featuredCaller);
  const callActive = useAtomValue(atoms.calling.callActive);

  // Define volume limits based on conditions
  const volumeLimit = isFeaturedCaller ? 30 : callActive ? 70 : 100;

  // Synchronize localValue with streamVolume only when not dragging
  useEffect(() => {
    if (!isDragging && localValue !== streamVolume) {
      setLocalValue(streamVolume);
      valueRef.current = streamVolume;
    }
  }, [isDragging, streamVolume, localValue]);

  const calculateValue = (clientX: number) => {
    if (sliderRef.current) {
      const { left, width } = sliderRef.current.getBoundingClientRect();
      const relativeX = clientX - left;
      let newValue = (relativeX / width) * (max - min) + min;
      newValue = Math.round(newValue / step) * step;
      newValue = Math.min(Math.max(newValue, min), volumeLimit);
      return newValue;
    }
    return valueRef.current;
  };

  const handleMouseMove = (event: MouseEvent) => {
    const newValue = calculateValue(event.clientX);
    if (newValue === 0 && localValue !== 0) {
      // If the slider reaches 0 and it's not already 0, mute the stream
      toggleIsMuted();
      setLocalValue(0);
      valueRef.current = 0;
      return;
    }

    if (newValue !== valueRef.current && newValue > 0) {
      valueRef.current = newValue;
      setLocalValue(newValue);
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if ((!isDragging && isMuted) || (localValue === 0 && !isMuted)) {
      toggleIsMuted();
    }
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setStreamVolume(valueRef.current);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const ballPosition = ((localValue - min) / (max - min)) * 100;

  return (
    <div
      css={sliderContainerStyle}
      ref={sliderRef}
      onMouseDownCapture={handleMouseDown}
    >
      <div css={sliderTrackStyle}>
        <div css={sliderFilledStyle(ballPosition)}></div>
        <div css={sliderOverlayStyle(100 - ballPosition)}></div>
      </div>
      <div css={sliderBallStyle(ballPosition)} />
    </div>
  );
};

export default Slider;

const sliderContainerStyle = css({
  position: "relative",
  width: "50px",
  height: "5px",
  cursor: "pointer",
});

const sliderTrackStyle = css({
  position: "absolute",
  width: "100%",
  height: "5px",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  borderRadius: "5px",
});

const sliderFilledStyle = (width: number) =>
  css({
    position: "absolute",
    height: "100%",
    backgroundColor: "white",
    borderRadius: "5px 0 0 5px",
    width: `${width}%`,
    zIndex: 1,
  });

const sliderOverlayStyle = (width: number) =>
  css({
    position: "absolute",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "0 5px 5px 0",
    right: 0,
    width: `${width}%`,
  });

const sliderBallStyle = (leftPosition: number) =>
  css({
    position: "absolute",
    top: "-5px",
    width: "1rem",
    height: "1rem",
    backgroundColor: "white",
    borderRadius: "50%",
    zIndex: 2,
    cursor: "pointer",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
    transition: "transform 0.1s ease",
    left: `calc(${leftPosition}% - 10px)`,
    "&:active": {
      transform: "scale(1.2)",
    },
  });
