import { colors } from "@/styles/global.styles";
import { isMobile } from "@/utils/helpers/Utils";
import { DEFAULT_AVATAR } from "@/utils/hooks/useDirectorAuthentication";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  messageAvatarStyle,
  messageCardStyle,
  messageContentStyle,
  messageRowStyle,
  messageUserNameStyle,
} from "../EventStream.style";
import { Message } from "../helpers/EventStreamUtils";
import StreamMessageInput from "./StreamMessageInput";

export type EventStreamMessageContainerApi = {
  init: (messages: Message[]) => void;
  addMessages: (message: Message[]) => void;
  removeMessages: (id: string[]) => void;
  scrollToBottom: VoidFunction;
  isInit: boolean;
};

type EventStreamMessageContainerProps = {
  enabled: boolean;
  profileComplete?: boolean;
  selectedStream?: string;
  handleActionToParticipate?: () => void;
  sendReaction?: (key: string) => void;
  sendMessage?: () => void;
  isStreamActive: boolean;
  onShowNotification?: (show: boolean) => void;
};

const EventStreamMessageContainer = ({
  enabled,
  profileComplete,
  selectedStream,
  handleActionToParticipate,
  sendReaction,
  sendMessage,
  isStreamActive,
  onShowNotification,
  ref,
}: EventStreamMessageContainerProps & {
  ref: React.RefObject<EventStreamMessageContainerApi | null>;
}) => {
  const firstLoadRef = useRef(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<
    { id: string; node: React.ReactNode }[]
  >([]);
  const [boolScroll, setBoolScroll] = useState(false);

  useImperativeHandle(ref, () => ({
    init,
    addMessages,
    removeMessages,
    scrollToBottom,
    isInit: true,
  }));

  const init = (messages: Message[]) => {
    setBoolScroll(true);
    setMessages(messages.slice().reverse().map(renderMessage));
    setTimeout(() => {
      setBoolScroll(false);
    }, 100);
  };

  const removeMessages = (ids: string[]) => {
    setMessages((prev) => {
      const updatedMessageList = prev.filter(
        (message) => !ids.includes(message.id)
      );

      return updatedMessageList;
    });
  };

  const addMessages = (messages: Message[]) => {
    setMessages((prevMessages) => {
      if (!containerRef.current) {
        return [];
      }

      const updatedMessages = [...prevMessages, ...messages.map(renderMessage)];

      const scrollDifference =
        containerRef.current.scrollHeight -
        containerRef.current.clientHeight -
        containerRef.current.scrollTop;

      if (scrollDifference <= 100) {
        setBoolScroll(true);
        scrollToBottom();
        setTimeout(() => {
          setBoolScroll(false);
        }, 100);
      } else {
        onShowNotification?.(true);
      }

      return updatedMessages;
    });
  };

  const scrollToBottom = () => {
    onShowNotification?.(false);
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (boolScroll || firstLoadRef.current) {
      scrollToBottom();
      firstLoadRef.current = false;
    }
  }, [messages, boolScroll]);

  const avatars = new Map<string, string>();

  const renderMessageParts = (content: string, mentions: string[]) => {
    const messageParts: React.ReactNode[] = [];
    let lastIndex = 0;

    for (const [index, mention] of mentions.entries()) {
      const startIndex = content.indexOf(mention, lastIndex);
      if (startIndex !== -1) {
        messageParts.push(content.substring(lastIndex, startIndex));
        messageParts.push(
          <span
            key={`mention-${index}`}
            css={{ color: colors.primaryAccentColor }}
          >
            {mention}
          </span>
        );
        lastIndex = startIndex + mention.length;
      }
    }

    messageParts.push(content.substring(lastIndex));
    return messageParts;
  };

  const renderMessage = (message: Message) => {
    const { sender, content, mentions = [], creationTimestamp, id } = message;
    const name = sender.name || "Odience User";
    const avatar = !sender.avatar ? DEFAULT_AVATAR : sender.avatar;
    if (!avatars.has(sender.id)) {
      avatars.set(sender.id, `${avatar}?${Date.now()}`);
    }
    const date = new Date(creationTimestamp).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const messageParts = renderMessageParts(content, mentions);

    return {
      id,
      node: (
        <div key={id} css={messageCardStyle}>
          <div css={messageAvatarStyle}>
            <img
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = DEFAULT_AVATAR;
              }}
              src={avatars.get(sender.id)}
              alt={`User: ${name} Avatar`}
              css={{ width: "100%", height: "100%" }}
            />
          </div>
          <div css={{ display: "flex", flexDirection: "column" }}>
            <div css={messageRowStyle}>
              <div css={messageUserNameStyle}>{name}</div>
              <div css={{ fontSize: isMobile() ? "0.8rem" : "1rem" }}>•</div>
              <div>{date}</div>
            </div>
            <div css={messageContentStyle}>{messageParts}</div>
          </div>
        </div>
      ),
    };
  };

  return (
    <>
      <div
        ref={containerRef}
        css={{
          overflowY: enabled ? "scroll" : "hidden",
          overflowX: "hidden",
          padding: isMobile() ? "0" : "1.4em 2.5em 1.4em 0",
          display: "flex",
          flexDirection: "column",
          gap: "0.7em",
          marginBottom: "1.4em",
          height: isMobile() ? "45vh" : "100%",
          boxSizing: "border-box",
          width: isMobile() ? undefined : "28vw",
          maxWidth: "28em",

          paddingBottom: isMobile() ? "8.5em" : "0",
          paddingTop: isMobile() ? "1em" : "0",
          paddingLeft: isMobile() ? "2em" : undefined,
          WebkitOverflowScrolling: "touch",
          zIndex: 2,
          "&::-webkit-scrollbar": {
            backgroundColor: "black",
            scrollbarColor: "black",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "#1c1c1c",
          },
        }}
      >
        {enabled && messages.map((current) => current.node)}
      </div>
      {isMobile() && isStreamActive && (
        <div
          css={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 1000,
            backgroundColor: "rgb(20, 20, 20)",
            height: "3em",
            display: "flex",
          }}
        >
          <StreamMessageInput
            profileComplete={profileComplete!}
            enabled={enabled}
            selectedStream={selectedStream!}
            handleActionToParticipate={handleActionToParticipate!}
            sendMessage={sendMessage!}
            sendReaction={sendReaction!}
          />
        </div>
      )}
    </>
  );
};

export default EventStreamMessageContainer;
