import { BAD_CONNECTION_TOAST_ID } from "@/utils/constants/common";
import { useNetworkState } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { WeakConnectionAnimation } from "../shared/Loaders/ReconnectingAnimation";

type NetworkStateProps = {
  v?: React.RefObject<any | null>;
  onConnectionStatusChange: (isPoorConnection: boolean) => void;
};

function NetworkState({ v, onConnectionStatusChange }: NetworkStateProps) {
  const [downlink, setDownlink] = useState(0);
  const timeReceived = useRef<number>(0);
  const [freezeInc, setFreezeInc] = useState(0);
  const freezeCount = useRef<number>(0);
  const bytesReceived = useRef<number>(0);
  const network = useNetworkState();

  useEffect(() => {
    let t: number;
    const doStatUpdate = async () => {
      const pc = v?.current?.getRtc();
      if (pc) statUpdate(await pc.getStats());

      t = +setTimeout(doStatUpdate, 1000);
    };
    t = +setTimeout(doStatUpdate, 1000);

    return () => {
      clearTimeout(t);
    };
  }, [v]);

  const statUpdate = (stats: RTCStatsReport) => {
    const currentTime = Date.now();

    for (const [, report] of stats) {
      if (
        report.kind === "video" &&
        report.type === "inbound-rtp" &&
        currentTime !== timeReceived.current
      ) {
        const bytes = report.bytesReceived;
        if (bytes) {
          setDownlink(
            (bytes - bytesReceived.current) /
              (currentTime - timeReceived.current)
          );
          bytesReceived.current = bytes;
          timeReceived.current = currentTime;
        }
        const freeze = report.freezeCount;
        if (freeze) {
          setFreezeInc(freeze - freezeCount.current);
          freezeCount.current = freeze;
        }
      }
    }
  };

  useEffect(() => {
    if (network.downlink || downlink > 0) {
      const isPoorConnection =
        !!(downlink < 100 && bytesReceived.current > 10000 && freezeInc) ||
        !!(network.downlink && network.downlink < 1.5);
      if (isPoorConnection) {
        toast(
          "Poor Internet Connection.\nYou may experience interruptions or lower resolution during playback",
          {
            style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
            icon: <WeakConnectionAnimation />,
            id: BAD_CONNECTION_TOAST_ID,
            duration: Infinity,
          }
        );
      } else {
        toast.dismiss(BAD_CONNECTION_TOAST_ID);
      }
      onConnectionStatusChange(isPoorConnection);
    } else {
      toast.dismiss(BAD_CONNECTION_TOAST_ID);
      onConnectionStatusChange(false);
    }
    return () => {
      toast.dismiss(BAD_CONNECTION_TOAST_ID);
    };
  }, [network.online, network.downlink, downlink, bytesReceived, freezeInc]);

  return null;
}

export default NetworkState;
