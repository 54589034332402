import { discardMessageNotificationAndOverlay } from "@/components/navigation/Navigation";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import { useProfileModal } from "@/pages/profile/utils/ProfileModalContext";
import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { directorUrl, routerUrl } from "@/utils";
import { generateRandomString, isMobile } from "@/utils/helpers/Utils";
import { atoms } from "@/utils/helpers/atoms";
import {
  getLocalDirectorToken,
  getLocalUser,
} from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import {
  getLastEventId,
  getLastGroupId,
  removeLastEventId,
  removeLastGroupId,
} from "@/utils/helpers/sessionStorage";
import { useToast } from "@/utils/helpers/toastManager";
import { useCall } from "@/utils/hooks/useCall";

import {
  MediaAlignment,
  MediaLayout,
} from "@/components/chatScreen/chat/typings/moderatorChatbotInfo";
import { updateContactInExistingConversations } from "@/utils/contacts";
import { WebGwContactList } from "@/utils/helpers/WebGwContact";
import { isChatbot } from "@/utils/helpers/chatbots";
import { useExponentialBackoff } from "@/utils/hooks/useExponentialBackoff";
import useParentIframeUrl from "@/utils/hooks/useUpdateIframeParent";
import { useSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import { cleanPhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { isSocketIOConnected } from "@/utils/network";
import { css } from "@emotion/react";
import { useNetworkState } from "@uidotdev/usehooks";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import { useUnmount } from "usehooks-ts";
import {
  DEFAULT_AVATAR,
  OdienceEvent,
  OdienceUser,
  authenticateDirector,
  isAllowed,
} from "../../../../utils/hooks/useDirectorAuthentication";
import {
  generateEventListPath,
  useOdienceEvents,
} from "../../../../utils/hooks/useOdienceEvents";
import { useOdienceOrganization } from "../../../../utils/hooks/useOdienceOrganization";
import GetAppModal from "../../components/GetAppModal";
import LoadingAnimation from "../../components/LoadingAnimation";
import OdiencePreviewMobileHeader from "../../components/OdiencePreviewMobileHeader";
import EventPoll from "./components/EventPoll";
import EventStreamActivityPanel from "./components/EventStreamActivityPanel";
import EventStreamHeader from "./components/EventStreamHeader";
import EventStreamMessageContainer, {
  EventStreamMessageContainerApi,
} from "./components/EventStreamMessageContainer";
import FeedItemsModal from "./components/FeedItemsModal";
import StreamCenter from "./components/StreamCenter";
import VideoWallRequest from "./components/VideoWallRequest";
import {
  CarouselOrientationType,
  FeedItemList,
  MediaPool,
  Message,
  MessageUpdate,
  Participant,
  STREAM_TYPE_MAIN,
  STREAM_TYPE_PIP,
  SelectedStreamsType,
  Stream,
  StreamCenterRef,
  StreamInfosType,
  StreamsList,
  TextErrorLoading,
  UserWallReactionsData,
  handleUpdateLiveStreamSwitching,
  hideStreamSelection,
} from "./helpers/EventStreamUtils";

type EventStreamProps = { odienceOnly?: boolean };

const SOCKET_RECONNECTION_MAX_RETRY = 3;
const EventStream = ({ odienceOnly = false }: EventStreamProps) => {
  const { openModal, userAvatar, updateUserAvatar, userDisplayName } =
    useProfileModal();
  const { canAcceptOrMakeCall, callWithVideo } = useCall();
  const { showToast, dismissToast } = useToast();
  const { objEvents, isFetching } = useOdienceEvents({
    updateUserAvatar,
  });
  const [hasDisplayName, setHasDisplayName] = useState(!!userDisplayName);
  const setChatBotMediaExtraInfo = useSetAtom(
    atoms.odience.chatBotMediaExtraInfo
  );
  const [objEvent, setObjEvent] = useState<OdienceEvent | null>(null);
  const [odienceAvatar, setOdienceAvatar] = useState<string>(
    userAvatar || DEFAULT_AVATAR
  );
  const [getAppModalOpen, setGetAppModalOpen] = useState(false);

  const [pendingInitMessages, setPendingInitMessages] = useState<Message[]>([]);
  const [pendingNewMessages, setPendingNewMessages] = useState<Message[]>([]);
  const [pendingDeletedMessages, setPendingDeletesMessages] = useState<
    string[]
  >([]);

  const [messageToSend, setMessageToSend] = useAtom(
    atoms.odience.messageToSend
  );
  const setStreamParticipantsPhoneNumbers = useSetAtom(
    atoms.odience.streamParticipantsPhoneNumbers
  );
  const messagesContainerRef = useRef<EventStreamMessageContainerApi>(null);

  const [participantCount, setParticipantCount] = useState(0);
  const [participantList, setParticipantList] = useState<Participant[]>([]);

  const [feedItems, setFeedItems] = useState<FeedItemList>({});
  const [selectedFeedItemId, setSelectedFeedItemId] = useState<string | null>(
    null
  );
  const [isFeedItemsModalOpen, setFeedItemsModalOpen] = useState(false);

  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselOrientation, setCarouselOrientation] =
    useState<CarouselOrientationType>("horizontal");

  const [reactionData, setReactionData] = useState<UserWallReactionsData>();
  const [, setTotalReactions] = useState<number | null>(null);

  const [mediaPool, setMediaPool] = useState<MediaPool | null>(null);

  const [streamDefaultRotation, setStreamDefaultRotation] = useState(0);

  const [showEventEnded, setShowEventEnded] = useState(false);

  const { eventId } = useParams();

  const { embeddedMode } = useOdienceOrganization();
  const network = useNetworkState();

  const [availableEventStreams, setAvailableEventStreams] = useState<Stream[]>(
    []
  );
  const [selectedStreams, setSelectedStreams] = useState<SelectedStreamsType>({
    main: {
      id: "0",
      serverId: "",
      isRunning: false,
      is360Stream: true,
      type: STREAM_TYPE_MAIN,
    },
  });
  const [standbyImage, setStandbyImage] = useState("");

  const arrStreamsForSockets = useRef<Stream[]>([]);
  const socketRef = useRef<SocketIOClient.Socket | null>(null);
  const streamCenterRef = useRef<StreamCenterRef>(null!);
  const [errorLoading, setErrorLoading] = useState(false);

  const [videoWallRequested, setVideoWallRequested] = useState(false);
  const [frontRowRequested, setFrontRowRequested] = useState(false);
  const callActive = useAtomValue(atoms.calling.callActive);
  const [isVideoWallCall, setIsVideoWallCall] = useAtom(
    atoms.odience.isVideoWallCall
  );
  const [isFrontRowCall, setIsFrontRowCall] = useAtom(
    atoms.odience.isFrontRowCall
  );
  const setFeaturedCaller = useSetAtom(atoms.odience.featuredCaller);
  const odienceDevice = useRef<{ id: number; name: string } | undefined>(
    undefined
  );
  const [deviceId, setDeviceId] = useState("");
  const usePreviewFrame = useAtomValue(atoms.odience.doUsePreviewFrame);
  const [mutedByModerator, setMutedByModerator] = useAtom(
    atoms.odience.mutedByModerator
  );
  const callMuted = useAtomValue(atoms.calling.callMuted);
  const [micVolume, setMicVolume] = useAtom(atoms.odience.streamMicVolume);
  const [streamVolume, setStreamVolume] = useAtom(atoms.odience.streamVolume);
  const [lastVolume, setLastVolume] = useState(streamVolume / 100);
  const [previousVolume, setPreviousVolume] = useAtom(
    atoms.odience.previousVolume
  );
  const [isStreamActive, setIsStreamActive] = useState(false);
  const navigate = useNavigate();
  const profileComplete = !!(hasDisplayName || userDisplayName);
  const [itemsOnList, setItemsOnList] = useState<string[]>([]);

  const [loadingCountdown, setLoadingCountdown] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (!callActive) {
      setLastVolume(streamVolume / 100);
    }
  }, [streamVolume, callActive]);

  useEffect(() => {
    if (!callActive) {
      setPreviousVolume(lastVolume * 100);
    }
  }, [lastVolume, callActive]);
  const updateIframeUrl = useParentIframeUrl({
    initialUrl: window.location.href,
    embeddedMode,
  });

  const secondsToStartErrorLoadingCountdown = 10;
  const secondsToReloadIfNoEvent = 10;

  const handleOnAddItemToList = (id: string) => {
    if (!itemsOnList.includes(id)) {
      setItemsOnList((previous) => [...previous, id]);
    }
  };

  const handleNavigateBack = async (toEventList = false) => {
    const lastGroupId = getLastGroupId();
    const lastEventId = getLastEventId();
    const provisioned = isProvisioned();

    let nagivateTo;

    if (!toEventList && lastGroupId && lastEventId) {
      nagivateTo = generatePath(
        provisioned ? paths.details : paths.previewOdienceDetails,
        {
          groupId: lastGroupId,
          eventId: lastEventId,
        }
      );
    } else {
      nagivateTo = provisioned ? paths.odience : paths.previewOdience;
    }
    handleResetOdienceCallStates();
    await navigate(nagivateTo);
  };

  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );

  const isLoggedIn = useAtomValue(atoms.provisioning.isLoggedIn);
  const selectedConversationId = useSelectedConversationId();

  useEffect(() => {
    updateIframeUrl(window.location.href);
  }, []);

  // Anytime selected conversation changes (chat overlay), we set the media extra info (Images alignment) for chatbots
  useEffect(() => {
    setChatbotMediaExtraInfo();
  }, [selectedConversationId, objEvent?.chatbots]);

  useEffect(() => {
    let counter;
    if (!objEvent && loadingCountdown !== undefined) {
      if (loadingCountdown > 0) {
        counter = setInterval(() => {
          setLoadingCountdown((previous) => --previous!);
        }, 1000);
      } else {
        window.location.reload();
      }
    }

    return () => {
      if (counter) {
        clearInterval(counter);
      }
    };
  }, [loadingCountdown, objEvent]);

  useEffect(() => {
    setOdienceAvatar(userAvatar);
  }, [userAvatar]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleCheckIfEventIsActive = (
    eventList: OdienceEvent[],
    eventId: string
  ) => {
    const eventExists = eventList.some((event) => event.id === eventId);

    setShowEventEnded(!eventExists);
    if (!eventExists) {
      removeLastEventId();
      removeLastGroupId();
      setSelectedStreams((previous) => ({
        main: { ...previous.main, isRunning: false },
        ...(previous.pip ? { pip: { ...previous.pip, isRunning: false } } : {}),
      }));
    }
  };

  useEffect(() => {
    // Because use could have been not provisioned before, do not check if there is a current request for fetching the events (previous events are the ones when not provisioned and therefore will miss private events)
    if (eventId && objEvents && !isFetching) {
      handleCheckIfEventIsActive(objEvents, eventId);
    }
  }, [objEvents, isFetching]);

  const handleActionToParticipate = async () => {
    localStorage.setItem("odienceEventIdKey", eventId!);
    if (isProvisioned()) {
      openModal();
    } else {
      await navigate(paths.provisioningFromOdience);
    }
  };

  const toggleGetAppModal = () => {
    setGetAppModalOpen(!getAppModalOpen);
  };

  const handleErrorLoading = () => {
    setErrorLoading(!errorLoading);
  };

  const handleTotalParticipantCount = (data: { count: number }) => {
    setParticipantCount(data.count);
  };

  const handleParticipantJoined = (data: { list: any[] }) => {
    const joinedUsers = data.list;
    const userId = (getLocalUser() ?? "").replaceAll("+", "");
    const filteredJoinedUsers = joinedUsers.filter(
      (user) => user.id !== userId
    );

    setParticipantList((prevParticipants) => {
      const res = sortUsersByLocalThenName([
        ...prevParticipants,
        ...filteredJoinedUsers,
      ]);
      updateStreamParticipantsPhoneNumbers(res);
      return res;
    });
  };

  const handleParticipantInfoUpdate = (data: Participant) => {
    const updateSIP = data.sip.replace("+", "");
    setParticipantList((prevParticipants) => {
      const res = sortUsersByLocalThenName(
        prevParticipants
          .filter(
            (participant) => updateSIP !== participant.sip.replace("+", "")
          )
          .concat([data])
      );
      updateStreamParticipantsPhoneNumbers(res);
      return res;
    });
  };

  const handleParticipantLeft = (data: { list: any[] }) => {
    const leavingSIPs = data.list.map((user) => user.sip);
    setParticipantList((prevParticipants) => {
      const res = sortUsersByLocalThenName(
        prevParticipants.filter(
          (participant) => !leavingSIPs.includes(participant.sip)
        )
      );
      updateStreamParticipantsPhoneNumbers(res);
      return res;
    });
  };

  const updateStreamParticipantsPhoneNumbers = (
    participants: Participant[]
  ) => {
    setStreamParticipantsPhoneNumbers(
      participants.map((participant) => cleanPhoneNumber(participant.sip))
    );
  };

  const sortUsersByLocalThenName = (participantList: Participant[]) => {
    const userId = (getLocalUser() ?? "").replaceAll("+", "");
    const userIds = {};

    return participantList
      .filter((current) => {
        return userIds[current.id] ? false : (userIds[current.id] = true);
      })
      .sort((a, b) => {
        if (a.id === userId) {
          return -1;
        } else if (b.id === userId) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
  };

  const handleParticipantList = (data: { list: Participant[] }) => {
    const res = sortUsersByLocalThenName(data.list);
    setParticipantList(res);
    updateStreamParticipantsPhoneNumbers(res);
  };

  const handleOdienceAvatarError = () => {
    setOdienceAvatar(DEFAULT_AVATAR);
  };

  const findAndConnectStream = async (data: {
    value: boolean;
    streamId: string;
    position?: StreamInfosType["position"];
    type: StreamInfosType["type"];
  }) => {
    if (!data.value && data.type === STREAM_TYPE_PIP) {
      setSelectedStreams((previous) => ({ main: previous.main }));
      return;
    }

    if (arrStreamsForSockets.current.length === 0) {
      console.error("findAndConnectStream no streams loaded yet");
      return;
    }

    const stream = arrStreamsForSockets.current.find(
      (x) => x.id === data.streamId
    );

    if (stream && stream.info.urls.length > 0) {
      await connectToStream(stream.info, data.type, data.position);
      if (stream.info.urls[0].status) {
        const isRunning = JSON.parse(stream.info.urls[0].status).is_running;
        setSelectedStreams((previous) => ({
          ...previous,
          [data.type === STREAM_TYPE_MAIN ? "main" : "pip"]: {
            ...previous[data.type === STREAM_TYPE_MAIN ? "main" : "pip"],
            isRunning,
          },
        }));
      }
    } else {
      console.log(
        `findAndConnectStream no streams found for id ${data.streamId}`
      );
    }
  };

  const updateStreamStatus = (selectedStream: Stream) => {
    if (selectedStream.info.urls[0].status) {
      const isRunning = JSON.parse(
        selectedStream.info.urls[0].status
      ).is_running;
      setSelectedStreams((previous) => ({
        ...previous,
        main: {
          ...previous.main,
          isRunning,
        },
      }));
    }
  };

  const handleStreamsList = async (streams: StreamsList) => {
    if (streams.settings.media_pool) {
      setMediaPool(streams.settings.media_pool as MediaPool);
    }
    setAvailableEventStreams(streams.list);

    if (getLocalDirectorToken() === "" || odienceOnly) {
      if (!isProvisioned()) {
        const selectedStream =
          streams.list.find((stream) => stream.selected) || streams.list[0];

        setSelectedStreams((previous) => ({
          ...previous,
          main: {
            id: selectedStream.id,
            serverId: selectedStream.info.urls[0].serverId,
            is360Stream: selectedStream.info.is_360,
            isRunning: true,
            type: STREAM_TYPE_MAIN,
          },
        }));
        updateStreamStatus(selectedStream);
      }
    }

    arrStreamsForSockets.current = streams.list;

    let pipStream;

    const mainStream =
      streams.list
        .filter((current) => {
          const isPipStream = current.info.type === STREAM_TYPE_PIP;

          if (isPipStream && current.info.position) {
            pipStream = current;
          }

          return !isPipStream; // Exclude PIP streams from mainStream selection
        })
        .find((current) => current.selected) ||
      streams.list.find((current) => current.info.type !== STREAM_TYPE_PIP);

    if (isProvisioned()) {
      const connectAndSetStream = async (stream, type) => {
        setStreamDefaultRotation(stream.info.rotation);
        await connectToStream(stream.info, type);
        if (stream.info.urls[0].status) {
          const isRunning = JSON.parse(stream.info.urls[0].status).is_running;
          setSelectedStreams((previous) => ({
            ...previous,
            [type === STREAM_TYPE_MAIN ? "main" : "pip"]: {
              ...previous[type === STREAM_TYPE_MAIN ? "main" : "pip"],
              isRunning,
            },
          }));
        }
      };

      if (mainStream) {
        await connectAndSetStream(mainStream, STREAM_TYPE_MAIN);

        if (pipStream && pipStream.info.active) {
          await connectAndSetStream(pipStream, STREAM_TYPE_PIP);
        }
      }
    }
    setStandbyImage(streams.settings.standby_media.url);
  };

  useEffect(() => {
    console.log(
      "Updated Selected Streams and Standby",
      selectedStreams,
      standbyImage
    );
  }, [selectedStreams, standbyImage]);

  const connectToStream = async (
    streamInfo: any,
    type: StreamInfosType["type"],
    position?: StreamInfosType["position"]
  ) => {
    const url = streamInfo.urls[0].url;
    const apiUrl = url;
    const headers =
      getLocalDirectorToken() === ""
        ? undefined
        : {
            headers: {
              Authorization: `Bearer ${getLocalDirectorToken()}`,
            },
          };

    try {
      const response = await fetch(apiUrl, headers);
      if (response.status === 302 || response.status === 401) return;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const data = (await response.json()) as { url: string };
        if (data) {
          const arrUrl = data.url.split("live/")[1];
          const streamIdentifier = arrUrl.split("?")[0];
          setSelectedStreams((previous) => ({
            ...previous,
            [type === STREAM_TYPE_MAIN ? "main" : "pip"]: {
              id: streamInfo.id,
              serverId: streamIdentifier,
              is360Stream: streamInfo.is_360,
              type,
              isRunning: true,
              position: position || streamInfo.position,
            },
          }));
        }
      }
    } catch (error) {
      console.error(`Error fetching data for ${url}: ${error}`);
    }
  };

  const sendMessage = () => {
    if (messageToSend.trim() !== "") {
      if (!isSocketIOConnected(socketRef)) {
        return;
      }

      socketRef.current.emit("MessageNew", { content: messageToSend });
      setMessageToSend("");
    }
  };

  const removeMessageById = (
    setMessagesList: (value: (prevMessages: Message[]) => Message[]) => void,
    update: MessageUpdate
  ) => {
    setMessagesList((prevMessages: Message[]) => {
      const updatedMessageList = prevMessages.filter(
        (message) => message.id !== update.id
      );

      return updatedMessageList;
    });
  };

  const handleSendReaction = (key: string) => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("UserWallReaction", {
      reaction: key,
    });
  };

  const setChatbotMediaExtraInfo = () => {
    if (
      !objEvent ||
      !selectedConversationId ||
      !isChatbot(selectedConversationId)
    ) {
      return;
    }

    const bot = objEvent.chatbots.find((chatbot) =>
      chatbot.bot_id.includes(selectedConversationId)
    );
    if (bot) {
      setChatBotMediaExtraInfo(
        bot.chatbot_image_alignment && bot.chatbot_image_style
          ? {
              mediaAlignment: bot.chatbot_image_alignment as MediaAlignment,
              mediaLayout: bot.chatbot_image_style as MediaLayout,
            }
          : undefined
      );
    }
  };

  const handleChatbotsList = (chatbotsList) => {
    if (eventId === chatbotsList.eventId) {
      setObjEvent((prevEvent) => {
        return (
          prevEvent && {
            ...prevEvent,
            chatbots: chatbotsList.list,
          }
        );
      });
    }
  };

  const handleUpdateEventInfo = (event: OdienceEvent) => {
    // For now only update name and chat on/off
    setObjEvent((prev) => {
      return (
        prev && {
          ...prev,
          brand: {
            ...prev.brand,
            brand_logo_padding: event.brand?.brand_logo_padding,
            brand_image_url: event.brand?.brand_image_url,
            brand_background_image_url: event.brand?.brand_background_image_url,
            brand_ad_image_url: event.brand?.brand_ad_image_url,
            brand_background_opacity: event.brand?.brand_background_opacity,
            brand_text_color: event.brand?.brand_text_color,
            brand_background_color: event.brand?.brand_background_color,
            brand_title: event.brand?.brand_title,
            brand_subtitle: event.brand?.brand_subtitle,
          },
          name: event.name,
          settings: {
            ...prev.settings,
            event_feature_chat: event.settings.event_feature_chat,
          },
        }
      );
    });
  };

  const handleEventsListUpdateAvailable = (eventId: string | undefined) => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("GetEventInfo", { eventId: eventId });
  };

  const handleOnConnectionChange = (poorConnection: boolean) => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("DeviceConnection", {
      poorConnection: poorConnection,
    });
  };

  const handleGetEventInfo = async (event: OdienceEvent) => {
    setLoadingCountdown(undefined);

    if (!isAllowed(event) && isProvisioned()) {
      await navigate(paths.odience);
    }

    if (event.event_ended) {
      handleEventEnded(event);
      return;
    }

    hideStreamSelection(event.live_stream_switching);
    setCarouselOpen(event.mini_carousel_open);
    setCarouselOrientation(
      event.mini_carousel_orientation as CarouselOrientationType
    );
    setObjEvent(event);
  };

  const handleOpenFeedModal = (itemId: string) => {
    if (itemId && Object.prototype.hasOwnProperty.call(feedItems, itemId)) {
      setSelectedFeedItemId(itemId);
    } else {
      const keys = Object.keys(feedItems);
      if (keys.length > 0) {
        setSelectedFeedItemId(keys[0]);
      }
    }
    setFeedItemsModalOpen(true);
  };

  const handleShowMiniCarousel = (input: {
    orientation: string;
    value: boolean;
  }) => {
    setCarouselOpen(input.value);
    setCarouselOrientation(input.orientation as CarouselOrientationType);
  };

  const selectMiniItem = (strFeedId = "") => {
    if (!carouselOpen) return;
    dismissToast?.();
    const miniFeedItems = document.querySelectorAll(".miniFeedItem");
    for (let i = 0; i < miniFeedItems.length; i++) {
      const item = miniFeedItems[i];
      item.classList.toggle("selected", item.classList.contains(strFeedId));
    }
    const keys = Object.keys(feedItems);
    if (!strFeedId && keys.length > 0) {
      strFeedId = keys[0];
    }
    setSelectedFeedItemId(strFeedId);
    handleOpenFeedModal(strFeedId);
  };

  const handleEventEnded = (event: OdienceEvent) => {
    if (event.id === eventId) {
      setShowEventEnded(true);
      removeLastEventId();
      removeLastGroupId();

      // TODO - we should discard notification and overlay only for bots inside the event
      discardMessageNotificationAndOverlay();
      disconnectSocket();
      streamCenterRef.current?.stopStreams();
    }
  };

  const disconnectSocket = () => {
    console.log("Disconnecting from socket");
    socketRef.current?.removeAllListeners();
    socketRef.current?.disconnect();
    socketRef.current = null;
  };

  const handleUsersWallReactions = (data: UserWallReactionsData) => {
    setReactionData({ ...data, timestamp: new Date().getTime() });
    const Over1000UserWallReactions = 1000;
    const totalReactions = data.total;

    if (totalReactions > Over1000UserWallReactions) {
      setTimeout(
        () => {
          setTotalReactions(totalReactions);
        },
        500 * (data.users.length / 2)
      );
    }
  };

  const fetchItems = async () => {
    try {
      const apiUrl = `${directorUrl}/mobile/chatbotFeedJson/${eventId}`;
      const response = await fetch(apiUrl);
      const items = await response.json();
      setFeedItems(items);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleResetOdienceCallStates = () => {
    setIsVideoWallCall(false);
    setVideoWallRequested(false);
    setIsFrontRowCall(false);
    setFrontRowRequested(false);
    setFeaturedCaller(false);
    setDeviceId("");
    setMutedByModerator(false);
    setStreamVolume(previousVolume);
  };

  const handleVideoWallBackNavigation = useCallback(() => {
    const confirmLeave = window.confirm(
      "Are you sure you want to go back? You are currently on the video wall."
    );

    if (confirmLeave) {
      handleResetOdienceCallStates();
      window.history.back();
    } else {
      window.history.forward();
    }
  }, []);

  useEffect(() => {
    if (!callActive) return;
    window.history.pushState(null, "", window.location.href);

    window.addEventListener("popstate", handleVideoWallBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleVideoWallBackNavigation);
    };
  }, [handleVideoWallBackNavigation, callActive]);

  const handleVideoWallLinked = (data) => {
    if (odienceDevice.current && data.deviceId === odienceDevice.current.id) {
      WebGwContactList.saveTransientContact(
        data.deviceSip,
        odienceDevice.current.name,
        updateContactInExistingConversations
      );
    }

    if (canAcceptOrMakeCall) callWithVideo(data.deviceSip);
    setDeviceId(data.deviceId);
  };

  const handleDeviceResponse = (accept: boolean, isVideoWallCall: boolean) => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("DeviceResponse", { content: accept });

    if (isVideoWallCall) {
      setVideoWallRequested(false);
      setIsVideoWallCall(accept);
    } else {
      setFrontRowRequested(false);
      setIsFrontRowCall(accept);
    }
  };

  const handleAcceptOdienceCallRequest = (isVideoWallCall: boolean) => {
    setLastVolume(streamVolume / 100);
    handleDeviceResponse(true, isVideoWallCall);
  };

  const handleDeclineOdienceCallRequest = (isVideoWallCall: boolean) => {
    handleDeviceResponse(false, isVideoWallCall);
  };

  const handleDeviceRequest = (request) => {
    switch (request.type) {
      case "front":
        setFrontRowRequested(!request.cancel && canAcceptOrMakeCall);
        odienceDevice.current = { id: request.id, name: request.name };

        if (request.cancel) {
          setIsFrontRowCall(false);
          showToast(`Front Row session cancelled`);
        }
        break;

      case "live":
        setVideoWallRequested(!request.cancel && canAcceptOrMakeCall);

        if (request.cancel) {
          setIsVideoWallCall(false);
          showToast(`Video Wall session cancelled`);
        }
        break;
    }
  };

  const handleLeaveOdienceCall = () => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }
    setStreamVolume(previousVolume);
    socketRef.current.emit("DeviceEnd", { deviceId });
    setDeviceId("");
    if (callActive && (isFrontRowCall || isVideoWallCall)) {
      showToast(
        `${isFrontRowCall ? "Front Row" : "Video Wall"} session terminated`
      );
    }
  };

  const sendPreviewFrames = () => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("UserPreviewFrame", { content: usePreviewFrame });
  };

  const handleActiveFeaturedCaller = (data) => {
    if (data.sip === getLocalUser()) {
      setFeaturedCaller(true);
      showToast("The moderator has elevated you to the featured caller");
    }
  };

  const handleDisableFeaturedCaller = (data) => {
    if (data.sip === getLocalUser()) {
      setFeaturedCaller(false);
      showToast("The moderator has removed you as the featured caller");
    }
  };

  const handleMicVolume = () => {
    setMicVolume(mutedByModerator || callMuted ? 0 : 100);
  };

  const sendStreamVolumeDetails = () => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("UpdateUserVolume", {
      stream: streamVolume,
      call: micVolume,
    });
  };

  const handleForceMuteDevice = (data) => {
    setMutedByModerator(data.value);
  };

  useEffect(() => {
    console.log("Media pool", mediaPool);
  }, [mediaPool]);

  useEffect(() => {
    if (callActive) {
      if (isVideoWallCall) {
        showToast(
          mutedByModerator
            ? "The Moderator has disabled your Video Wall Microphone."
            : "The Moderator has Enabled your Video Wall Microphone."
        );
      } else if (isFrontRowCall) {
        showToast(
          "The Moderator has turned on your microphone. If you prefer, you can turn if off."
        );
      }
    }
  }, [callActive, mutedByModerator]);

  useEffect(() => {
    if (!isVideoWallCall && !isFrontRowCall) {
      handleLeaveOdienceCall();
    }
  }, [isVideoWallCall, isFrontRowCall, deviceId]);

  useEffect(() => {
    if (usePreviewFrame && isVideoWallCall) {
      sendPreviewFrames();
    }
  }, [usePreviewFrame, isVideoWallCall]);

  useEffect(() => {
    if (isVideoWallCall || isFrontRowCall) {
      sendStreamVolumeDetails();
    }
  }, [micVolume, streamVolume]);

  useEffect(() => {
    handleMicVolume();
  }, [mutedByModerator, callMuted]);

  useEffect(() => {
    void connectSocket();

    return () => {
      disconnectSocket();
      stopReconnectSocketRetry();
    };
  }, [userAvatar, isLoggedIn, network.online]);

  /**
   * Because message container can be non initialized at the time messages arrived, we use a isInit flag instead of .current (re-rendering this component will always trigger this useEffect)
   * TODO: best would be to always have component ref messagesContainerRef rendered to avoid this useEffect. Right now the message component is only included after getting event info from socket.
   */
  useEffect(() => {
    if (
      !messagesContainerRef.current?.isInit ||
      pendingInitMessages.length === 0
    ) {
      return;
    }

    console.log("Pending init messages");

    messagesContainerRef.current.init(pendingInitMessages);
  }, [messagesContainerRef.current?.isInit, pendingInitMessages]);

  // See comment above
  useEffect(() => {
    if (
      !messagesContainerRef.current?.isInit ||
      pendingNewMessages.length === 0
    ) {
      return;
    }

    console.log("Pending new messages");

    messagesContainerRef.current.addMessages(pendingNewMessages);
  }, [messagesContainerRef.current?.isInit, pendingNewMessages]);

  // See comment above
  useEffect(() => {
    if (
      !messagesContainerRef.current?.isInit ||
      pendingDeletedMessages.length === 0
    ) {
      return;
    }

    console.log("Pending new messages");

    messagesContainerRef.current.removeMessages(pendingDeletedMessages);
  }, [messagesContainerRef.current?.isInit, pendingDeletedMessages]);

  useUnmount(() => {
    disconnectSocket();
    stopReconnectSocketRetry();
    setStreamParticipantsPhoneNumbers([]);
  });

  const connectSocket = async () => {
    if (!network.online) {
      return;
    }

    if (isProvisioned() && !isLoggedIn) {
      return;
    }

    void fetchItems();
    let userId;

    if (isProvisioned()) {
      const authenticatedUser = (await authenticateDirector()) as OdienceUser;
      if (authenticatedUser.avatar != null) {
        setOdienceAvatar(authenticatedUser.avatar);
      }
      userId = authenticatedUser.msisdn;
      setHasDisplayName(!!authenticatedUser.name);
    }

    disconnectSocket();
    setPendingInitMessages([]);
    setPendingNewMessages([]);
    setPendingDeletesMessages([]);
    const url = `${routerUrl}/${eventId}`;

    const option = isProvisioned()
      ? {
          transports: ["websocket"],
          query: { user_id: userId, token: getLocalDirectorToken() },
        }
      : {
          transports: ["websocket"],
          query: {
            user_id: `guest-${generateRandomString(15)}`,
          },
        };

    console.log("Creating new socket connection with ", option);

    socketRef.current = io(url, { ...option, reconnection: false });
    socketRef.current.on("Connected", () => {
      console.log("Connected to", socketRef.current);
      stopReconnectSocketRetry();

      // Sometimes director does not respond with the event info, if no event received in secondsToStartErrorLoadingCountdown, launch a timer to reload the page after secondsToReloadIfNoEvent
      setTimeout(() => {
        if (!objEvent) {
          setLoadingCountdown(secondsToReloadIfNoEvent);
        }
      }, secondsToStartErrorLoadingCountdown * 1000);
    });

    socketRef.current.on("MessagePublished", (message: Message) => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.addMessages([message]);
      } else {
        setPendingNewMessages((prev) => [...prev, message]);
      }
    });
    socketRef.current.on("MessagesList", (messages: { list: Message[] }) => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.init(messages.list);
      } else {
        setPendingInitMessages(messages.list);
      }
    });
    socketRef.current.on("MessageDeleted", (message) => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.removeMessages([message.id]);
      } else {
        setPendingDeletesMessages((prev) => [...prev, message.id]);
      }
    });
    socketRef.current.on("StreamsList", handleStreamsList);
    socketRef.current.on("UpdatePictureInPicture", (data) =>
      findAndConnectStream({ ...data, type: STREAM_TYPE_PIP })
    );
    socketRef.current.on("UpdateLiveStreamSwitching", (data) =>
      handleUpdateLiveStreamSwitching(data, hideStreamSelection)
    );
    socketRef.current.on("ForceStream", async (data) => {
      if (!isProvisioned()) {
        const newStream = arrStreamsForSockets.current.find(
          (stream) => stream.id === data.streamId
        );

        if (newStream) {
          setSelectedStreams((previous) => ({
            ...previous,
            main: {
              id: newStream.id,
              serverId: newStream.info.urls[0].serverId,
              is360Stream: newStream.info.is_360,
              isRunning: true,
              type: STREAM_TYPE_MAIN,
            },
          }));

          updateStreamStatus(newStream);
        }
      } else {
        await findAndConnectStream({
          ...data,
          value: true,
          type: STREAM_TYPE_MAIN,
        });
      }
    });
    socketRef.current.on("EventsListUpdateAvailable", () =>
      handleEventsListUpdateAvailable(eventId)
    );
    socketRef.current.on("EventInfo", (event) => handleGetEventInfo(event));
    socketRef.current.on("EventUpdated", (event) =>
      handleUpdateEventInfo(event)
    );
    socketRef.current.on("ChatbotsList", (chatbotsList) =>
      handleChatbotsList(chatbotsList)
    );
    socketRef.current.on(
      "ShowMiniCarousel",
      (input: { orientation: string; value: boolean }) =>
        handleShowMiniCarousel(input)
    );
    socketRef.current.on("UsersLeft", (data) => handleParticipantLeft(data));
    socketRef.current.on("UserInfoUpdate", (data) =>
      handleParticipantInfoUpdate(data)
    );
    socketRef.current.on("UsersJoined", (data) =>
      handleParticipantJoined(data)
    );
    socketRef.current.on("UsersList", (data) => handleParticipantList(data));
    socketRef.current.on("UsersCountChanged", handleTotalParticipantCount);
    socketRef.current.on("UsersWallReactions", handleUsersWallReactions);
    socketRef.current.on("DeviceRequest", (data) => handleDeviceRequest(data));
    socketRef.current.on("ForceMuteDevice", (data) =>
      handleForceMuteDevice(data)
    );
    socketRef.current.on("DeviceUnlinkAlert", () => {
      setIsVideoWallCall(false);
      setIsFrontRowCall(false);
      setFeaturedCaller(false);
    });
    socketRef.current.on("ShowFeaturedCaller", (data) =>
      handleActiveFeaturedCaller(data)
    );
    socketRef.current.on("HideFeaturedCaller", (data) =>
      handleDisableFeaturedCaller(data)
    );
    socketRef.current.on("EventEnded", handleEventEnded);
    socketRef.current.on("CatalogFeedUpdated", fetchItems);
    socketRef.current.on("clientError", (error) => {
      console.error("Socket client error:", error);
    });
    socketRef.current.on("DeviceLinked", handleVideoWallLinked);
    if (isVideoWallCall) {
      sendPreviewFrames();
    }

    socketRef.current.on("AccessDenied", async () => {
      await handleNavigateBack(true);
    });
    socketRef.current.on("EventNotFound", async () => {
      await handleNavigateBack(true);
    });
    socketRef.current.on("UpdateMediaPool", (data: MediaPool) => {
      setMediaPool(data);
    });

    socketRef.current.on("connect_timeout", (error) => {
      console.error("Socket connect timeout: ", error);
      tryReconnectSocket();
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("Socket connect error:", error);
      tryReconnectSocket();
    });

    socketRef.current.on("disconnect", (error) => {
      console.error("Socket disconnected: ", error);
      tryReconnectSocket();
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket disconnected: ", error);
      tryReconnectSocket();
    });
  };

  const tryReconnectSocket = () => {
    if (retryCountReached) {
      void handleNavigateBack(true);
    } else {
      reconnectSocket();
    }
  };

  const {
    run: reconnectSocket,
    stop: stopReconnectSocketRetry,
    retryCountReached,
  } = useExponentialBackoff(connectSocket, SOCKET_RECONNECTION_MAX_RETRY);

  const backUi = (
    <button id="backToList" onClick={() => handleNavigateBack()} css={button}>
      <img
        src="/odience/web_client/Exit.svg"
        alt="Back to EventList"
        css={buttonImage}
      />
    </button>
  );

  return (
    <>
      <style>
        {`
        html, body {
          overflow: hidden;
        }
      `}
      </style>
      {objEvent ? (
        <>
          {!showEventEnded && isFeedItemsModalOpen && (
            <FeedItemsModal
              feedItems={feedItems}
              selectedFeedId={selectedFeedItemId}
              show={isFeedItemsModalOpen}
              closeModal={() => setFeedItemsModalOpen(false)}
              selectMiniItem={selectMiniItem}
              chatbotList={objEvent?.chatbots}
              handleActionToParticipate={handleActionToParticipate}
              event={objEvent}
              itemsOnList={itemsOnList}
              onAddItemToList={handleOnAddItemToList}
            />
          )}
          <div
            css={[
              container,
              {
                paddingTop:
                  isProvisioned() && !embeddedMode
                    ? undefined
                    : embeddedMode || isMobile()
                      ? undefined
                      : "5vh",

                height:
                  embeddedMode && isProvisioned()
                    ? "100vh"
                    : !isProvisioned() || embeddedMode
                      ? "100vh"
                      : "90vh",
              },
            ]}
          >
            {!isMobile() ? (
              <EventStreamHeader
                odienceAvatar={odienceAvatar}
                handleOdienceAvatarError={handleOdienceAvatarError}
                handleOpenEditProfile={openModal}
                event={objEvent}
                selectedStreamId={selectedStreams.main.id}
                is360Stream={selectedStreams.main.is360Stream}
                availableEventStreams={availableEventStreams}
                onNavigateBack={handleNavigateBack}
                resetOdienceCallStates={handleResetOdienceCallStates}
                callActive={callActive}
              />
            ) : (
              !isLandscape && (
                <OdiencePreviewMobileHeader
                  page={"stream"}
                  participantCount={participantCount}
                />
              )
            )}
            <div
              css={{
                height: isMobile() ? "100vh" : "calc(100% - 10rem)",
                width: "100%",
                display: "flex",
                flexDirection: isMobile() ? "column" : "row",
                justifyContent: "center",
                gap: "1.5em",
                overflow: "hidden",
              }}
            >
              {!showEventEnded && isProvisioned() && (
                <EventPoll
                  socketRef={socketRef}
                  objEvent={objEvent}
                  eventId={eventId}
                  profileComplete={profileComplete}
                  onActionToParticipate={handleActionToParticipate}
                />
              )}
              {(videoWallRequested || frontRowRequested) && isProvisioned() && (
                <VideoWallRequest
                  profileComplete={profileComplete}
                  onActionToParticipate={handleActionToParticipate}
                  onActionToAccept={() =>
                    handleAcceptOdienceCallRequest(videoWallRequested)
                  }
                  onActionToDecline={() =>
                    handleDeclineOdienceCallRequest(videoWallRequested)
                  }
                  type={videoWallRequested ? "videoWall" : "frontRow"}
                />
              )}
              {!showEventEnded && (
                <StreamCenter
                  openFeedModal={handleOpenFeedModal}
                  selectedStreams={selectedStreams}
                  feedItems={feedItems}
                  event={objEvent}
                  ref={streamCenterRef}
                  carouselOpen={carouselOpen}
                  carouselOrientation={carouselOrientation}
                  selectMiniItem={selectMiniItem}
                  selectedMiniItemId={selectedFeedItemId}
                  reactionData={reactionData}
                  isLandscape={isLandscape}
                  errorLoading={errorLoading}
                  handleErrorLoading={handleErrorLoading}
                  standbyImage={standbyImage}
                  streamDefaultRotation={streamDefaultRotation}
                  onStreamActive={setIsStreamActive}
                  isStreamActive={isStreamActive}
                  mediaPool={mediaPool}
                  onConnectionChange={handleOnConnectionChange}
                  lastVolume={lastVolume}
                  setLastVolume={setLastVolume}
                />
              )}

              {!showEventEnded && !isMobile() && (
                <EventStreamActivityPanel
                  event={objEvent}
                  messagesContainerRef={messagesContainerRef}
                  participantCount={participantCount}
                  participantList={participantList}
                  profileComplete={profileComplete}
                  handleActionToParticipate={handleActionToParticipate}
                  selectedStream={selectedStreams.main.serverId}
                  sendMessage={sendMessage}
                  sendReaction={handleSendReaction}
                  isStreamActive={isStreamActive}
                />
              )}
              {!showEventEnded && isMobile() && !isLandscape && (
                <div
                  css={{
                    position: isLandscape ? "relative" : undefined,
                    height: isLandscape ? "100%" : "45%",
                    width: isLandscape ? "45%" : "100vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    css={{
                      flex: 1,
                      width: "100%",
                    }}
                  >
                    <EventStreamMessageContainer
                      enabled={objEvent.settings.event_feature_chat}
                      ref={messagesContainerRef}
                      profileComplete={profileComplete}
                      selectedStream={selectedStreams.main.serverId}
                      handleActionToParticipate={handleActionToParticipate}
                      sendMessage={sendMessage}
                      sendReaction={handleSendReaction}
                      isStreamActive={isStreamActive}
                    />
                  </div>
                </div>
              )}
            </div>
            {showEventEnded && (
              <ConfirmationPopup
                title={"Event has ended"}
                confirmationMessage="Thank you for participating. Join us again next time or explore the Events List for more events."
                handleAction={() =>
                  navigate(generateEventListPath(objEvent, embeddedMode))
                }
                primaryButtonText="Return to Event List"
                togglePopup={() =>
                  navigate(generateEventListPath(objEvent, embeddedMode))
                }
                closeButtonActive={false}
                secondaryButtonActive={false}
              />
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            position: "relative",
            width: isProvisioned() ? "100%" : "100vw",
            height: isProvisioned() ? "100%" : "100vh",
          }}
        >
          <div css={BackUIstyle}>{backUi}</div>
          <LoadingAnimation>
            <TextErrorLoading counter={loadingCountdown} />
          </LoadingAnimation>
        </div>
      )}

      {getAppModalOpen && (
        <GetAppModal
          show={getAppModalOpen}
          onCloseModal={toggleGetAppModal}
          objEvent={null}
        />
      )}
    </>
  );
};

export default EventStream;

const container = css({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  borderRadius: "20px",
  width: "100vw",
  alignItems: "center",
  backgroundColor: isMobile() ? "rgba(0, 0, 0)" : "#111",
  transition: "all .8s ease",
  overflow: "hidden",
  fontFamily: "Figtree, sans-serif",
  position: "relative",
});

const BackUIstyle = css({
  color: colors.primaryTextColor,
  borderRadius: "20px",
  height: "4em",
  backgroundColor: "#1c1c1c",
  display: "flex",
  gap: "2em",
  flexShrink: 0,
  alignItems: "center",
  transition: "all .2s ease",
  width: "fit-content",
  padding: "3em",
  position: "absolute",
});

const button = css({
  width: "2em",
  height: "2em",
});
const buttonImage = css({
  height: "2em",
  width: "2em",
  objectFit: "cover",
});
