import { colors } from "@/styles/global.styles";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Participant } from "../helpers/EventStreamUtils";
import EventStreamMessageContainer, {
  EventStreamMessageContainerApi,
} from "./EventStreamMessageContainer";
import StreamMessageInput from "./StreamMessageInput";
import StreamParticipants from "./StreamParticipants";
import StreamServices from "./StreamServices";

type EventStreamActivityPanelProps = {
  event: OdienceEvent;
  messagesContainerRef: React.RefObject<EventStreamMessageContainerApi | null>;
  participantCount: number;
  participantList: Participant[];
  profileComplete: boolean;
  handleActionToParticipate: () => void;
  selectedStream: string;
  sendMessage: () => void;
  sendReaction: (key: string) => void;
  isStreamActive: boolean;
};

const EventStreamActivityPanel = ({
  event,
  messagesContainerRef,
  participantCount,
  participantList,
  profileComplete,
  handleActionToParticipate,
  selectedStream,
  sendMessage,
  sendReaction,
  isStreamActive,
}: EventStreamActivityPanelProps) => {
  const [isStreamServicesPanelOpen, setIsStreamServicesPanelOpen] =
    useState(false);
  const [isParticipantsPanelOpen, setIsParticipantsPanelOpen] = useState(false);
  const [streamServicesImage, setStreamServicesImage] = useState(
    event?.brand?.brand_image_url
  );
  const StreamServicesDefaultImage = "/odience/web_client/Star_2.svg";
  useEffect(() => {
    setStreamServicesImage(
      event?.brand?.brand_image_url ?? StreamServicesDefaultImage
    );
  }, [event?.brand?.brand_image_url]);
  const toggleStreamServicesPanel = () =>
    setIsStreamServicesPanelOpen((prev) => !prev);
  const toggleParticipantsPanel = () =>
    setIsParticipantsPanelOpen((prev) => !prev);

  const brandLogoScale = `${(1 - event.brand?.brand_logo_padding) * 100}%`; // Calculate the scale based on the padding
  const [boolShowMessageNotification, setBoolShowMessageNotification] =
    useState(false);

  const ActivityPanelHeader = (
    <div css={headerStyles}>
      {event.chatbots.length > 0 && isProvisioned() && (
        <button
          onClick={toggleStreamServicesPanel}
          css={[
            streamServicesButtonStyles,
            { backgroundColor: event?.brand?.brand_background_color },
          ]}
        >
          <img
            src={streamServicesImage}
            alt="In Stream Services"
            css={brandIconStyle(brandLogoScale)}
          />
        </button>
      )}
      <button onClick={toggleParticipantsPanel} css={participantsButtonStyles}>
        <img
          src="/odience/web_client/User.svg"
          alt="View Participants"
          css={iconStyles}
        />
        <div css={participantCountStyles}>{participantCount}</div>
      </button>
      <button
        onClick={() => {
          setIsParticipantsPanelOpen(false);
          setIsStreamServicesPanelOpen(false);
        }}
        css={hiddenButtonStyles}
      >
        <img src="/odience/event/messages.svg" alt="Messages" />
      </button>
    </div>
  );

  return (
    <div css={panelStyles}>
      {ActivityPanelHeader}

      {!isParticipantsPanelOpen && !isStreamServicesPanelOpen && (
        <button
          id="message_notification"
          onClick={() => messagesContainerRef.current?.scrollToBottom()}
          css={[
            newMessageButtonStyle,
            {
              visibility: boolShowMessageNotification ? "visible" : "hidden",
              opacity: boolShowMessageNotification ? 1 : 0,
            },
          ]}
        >
          <div css={{ height: "100%", display: "flex" }}>
            <img
              src="/odience/event/arrow-down.svg"
              alt="New Message"
              css={{ width: "1.4em" }}
            />
          </div>
          <div>Latest Message</div>
        </button>
      )}

      <EventStreamMessageContainer
        enabled={event.settings.event_feature_chat}
        ref={messagesContainerRef}
        isStreamActive={isStreamActive}
        onShowNotification={setBoolShowMessageNotification}
      />

      <StreamParticipants
        isPanelOpen={isParticipantsPanelOpen}
        closePanel={toggleParticipantsPanel}
        initialParticipants={participantList}
        profileComplete={profileComplete}
        handleActionToParticipate={handleActionToParticipate}
      />

      <StreamServices
        event={event}
        isPanelOpen={isStreamServicesPanelOpen}
        profileComplete={profileComplete}
        handleActionToParticipate={handleActionToParticipate}
        toggleStreamServicesPanel={toggleStreamServicesPanel}
      />

      <StreamMessageInput
        profileComplete={profileComplete}
        enabled={event.settings.event_feature_chat}
        selectedStream={selectedStream}
        handleActionToParticipate={handleActionToParticipate}
        sendMessage={sendMessage}
        sendReaction={sendReaction}
      />
    </div>
  );
};

export default EventStreamActivityPanel;

const headerStyles = css({
  width: "85%",
  display: "flex",
  alignItems: "center",
  gap: "1em",
  marginTop: "1.4em",
  marginBottom: "1.4em",
});

const streamServicesButtonStyles = css({
  width: "3em",
  height: "3em",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  userSelect: "none",
  overflow: "visible",
  "&:hover:disabled": {
    opacity: 0.5,
  },
  "&:disabled": {
    pointerEvents: "none",
  },
});

const participantsButtonStyles = css({
  width: "3em",
  height: "2em",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  userSelect: "none",
  overflow: "visible",
  flexDirection: "column",
  gap: "0.3em",
  color: colors.primaryTextColor,
  "&:hover:disabled": {
    opacity: 0.5,
  },
  "&:disabled": {
    pointerEvents: "none",
  },
});

const iconStyles = css({
  width: "1.4em",
  height: "1.4em",
});

const brandIconStyle = (scale: string) =>
  css({
    width: scale,
    height: scale,
    borderRadius: "50%",
  });

const participantCountStyles = css({
  fontSize: "0.7rem",
});

const hiddenButtonStyles = css({
  display: "none",
  cursor: "pointer",
  marginLeft: "auto",
  userSelect: "none",
});

const panelStyles = css({
  display: "flex",
  alignItems: "center",
  position: "relative",
  backgroundColor: "#141414",
  flexDirection: "column",
  borderRadius: "10px",
  width: "30vw",
  height: "100%",
  boxSizing: "border-box",
  paddingLeft: "1vw",
});

const newMessageButtonStyle = css`
  cursor: pointer;
  color: white;
  display: flex;
  flex-direction: row;
  user-select: none;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  width: 12em;
  top: 80%;
  position: absolute;
  z-index: 999;
  gap: 1rem;
  background-color: #dc3545;
  left: 29%;
  font-weight: 300;
  font-size: 1rem;
  height: 3em;
  transition: all 0.2s ease;
`;
