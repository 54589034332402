import { colors } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import Conversation from "@/utils/messaging/conversation/Conversation";
import {
  disableIncomingNotificationForRemote,
  displayChatScreenOverlay,
} from "@/utils/messaging/conversation/conversationUtils";
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { FeedItem, FeedItemList } from "../helpers/EventStreamUtils";

type FeedItemsModalProps = {
  feedItems: FeedItemList;
  selectedFeedId: string | null;
  show: boolean;
  closeModal: () => void;
  selectMiniItem: (id: string) => void;
  chatbotList?: any[];
  handleActionToParticipate: () => void;
  event: OdienceEvent;
  itemsOnList: string[];
  onAddItemToList: (id: string) => void;
};

const FeedItemsModal = ({
  feedItems,
  selectedFeedId,
  show,
  closeModal,
  selectMiniItem,
  chatbotList,
  handleActionToParticipate,
  event,
  itemsOnList,
  onAddItemToList,
}: FeedItemsModalProps) => {
  const [selectedFeedItem, setSelectedFeedItem] = useState<FeedItem | null>(
    null
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState("");
  const feedItemsRowRef = useRef<HTMLDivElement>(null);
  const [saveListAvailable, setSaveListAvailable] = useState(false);
  const savedListConversationRef = useRef<Conversation | undefined>(undefined);
  const resetState = () => {
    setSelectedFeedItem(null);
    setCurrentIndex(0);
  };

  const selectFeedItem = (id: string) => {
    const feedItemKeys = Object.keys(feedItems);

    if (feedItemKeys.length === 0) return;
    const newIndex = feedItemKeys.findIndex((key) => key === id);
    if (newIndex === 0) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(newIndex);
    }

    const selectedItem = feedItems[feedItemKeys[newIndex]];
    setSelectedFeedItem(selectedItem);

    if (feedItemsRowRef.current) {
      const selectedFeedItemElement = document.getElementById(`miniFeed-${id}`);
      if (selectedFeedItemElement) {
        const container = feedItemsRowRef.current;
        const itemOffsetLeft = selectedFeedItemElement.offsetLeft;
        const containerWidth = container.clientWidth;
        container.scrollLeft = itemOffsetLeft - containerWidth / 2;
      }
    }
    selectMiniItem(id);
    setSelectedItemId(id);
  };

  const scrollFeedIntoView = (id: string) => {
    const selectedElement = document.getElementById(`miniFeed-${id}`);
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const onNext = () => {
    const keys = Object.keys(feedItems);
    const newIndex = currentIndex + 1;

    if (newIndex < keys.length) {
      const nextKey = keys[newIndex];
      setCurrentIndex(newIndex);
      selectFeedItem(nextKey);
      setSelectedItemId(nextKey);
      scrollFeedIntoView(nextKey);
    }
  };

  const onPrev = () => {
    if (currentIndex > 0) {
      const keys = Object.keys(feedItems);
      const newIndex = currentIndex - 1;

      if (newIndex >= 0 && newIndex < keys.length) {
        const prevKey = keys[newIndex];
        setCurrentIndex(newIndex);
        selectFeedItem(prevKey);
        setSelectedItemId(prevKey);
        scrollFeedIntoView(prevKey);
      }
    }
  };

  useEffect(() => {
    if (show) {
      resetState();
      if (selectedFeedId) {
        selectFeedItem(selectedFeedId);
      }
    }
  }, [selectedFeedId, show, feedItems]);

  useEffect(() => {
    if (chatbotList && event) {
      findEventSavedList(chatbotList);
    }
  }, [chatbotList]);

  const isAddedToSavedList = !!itemsOnList.find(
    (saved) => saved === selectedFeedItem?.id
  );

  // We don't want to display incoming chat notification every time an item is added to a saved list (bot will confirm the addition by message)
  // Enable it back once component finishes (Will be disabled again when current item is added)
  useEffect(() => {
    return () => {
      if (savedListConversationRef.current) {
        disableIncomingNotificationForRemote(
          savedListConversationRef.current.participants[0].getMainPhoneNumber(),
          false
        );
      }
    };
  }, [selectedFeedId]);

  if (Object.keys(feedItems).length < 1) {
    return null;
  }

  const findEventChatbotQR = (chatbotList: any[]) => {
    return chatbotList.find(
      (item: { payload: string; type: string }) =>
        item.type === "ecommerce" && item.payload !== "client_qr_scan"
    );
  };

  const findEventSavedList = (chatbotList: any[]) => {
    const savedItemsListchatbot = chatbotList.find(
      (item) => item.type === "saved_list" && item.payload !== "client_qr_scan"
    );

    if (savedItemsListchatbot) {
      setSaveListAvailable(true);
    }
    return savedItemsListchatbot;
  };

  const selectedItemGroupId = selectedFeedItem?.group_id
    ? selectedFeedItem?.group_id
    : selectedFeedItem?.item_group_id;

  const getSavedListConversation = () => {
    if (!chatbotList || !saveListAvailable) return;

    const savedItemsListchatbot = findEventSavedList(chatbotList);

    if (!savedItemsListchatbot) return;

    const contact = WebGwContact.fromChatbotInfo(savedItemsListchatbot);

    const phoneNumber = contact.getMainPhoneNumber();

    savedListConversationRef.current = Conversation.getOrCreate({
      phoneNumber,
      contactToLinkIfCreate: contact,
    }).conversation;

    return savedListConversationRef.current;
  };

  const sendMessageToBot = (type: "addItem" | "showList") => {
    const conversation = getSavedListConversation();

    if (!conversation || !selectedFeedItem || !selectedItemGroupId) return;

    conversation.sendTextMessage(
      `{"response":{"reply":{"postback":{"data":"nextState=show-item-external;listExternalId=${encodeURIComponent(event.id)};listName=${encodeURIComponent(event.name)};listItemExternalId=${encodeURIComponent(selectedItemGroupId)};itemName=${encodeURIComponent(selectedFeedItem?.title)};itemImageUrl=${selectedFeedItem?.images ? encodeURIComponent(selectedFeedItem?.images[0]) : encodeURIComponent(selectedFeedItem?.image_link)};externalCreateListNextState=create-item;externalListExistsNextState=show-item-external_item-fetch"},"displayText":"${type === "addItem" ? "Save" : "Show"} Item"}}}`,
      true
    );

    return conversation;
  };

  const addItemToSavedList = () => {
    const conversation = sendMessageToBot("addItem");

    if (!conversation) return;

    disableIncomingNotificationForRemote(
      conversation.participants[0].getMainPhoneNumber()
    );

    onAddItemToList(selectedItemId);
  };

  const handleOpenSavedList = () => {
    const conversation = sendMessageToBot("showList");

    if (!conversation) return;

    displayChatScreenOverlay(conversation.participants[0].getMainPhoneNumber());

    closeModal();
  };

  const addItemToCart = () => {
    if (!chatbotList || !selectedFeedItem || !selectedItemGroupId) return;

    const QRchatbot = findEventChatbotQR(chatbotList);
    const contact = WebGwContact.fromChatbotInfo(QRchatbot);

    const addItemToChatbotCart = () => {
      const phoneNumber = contact.getMainPhoneNumber();
      const { conversation } = Conversation.getOrCreate({
        phoneNumber,
        contactToLinkIfCreate: contact,
      });

      displayChatScreenOverlay(phoneNumber);

      conversation.sendTextMessage(
        `{"response":{"reply":{"postback":{"data":"nextState=product-getProductOptions;userSelectedProductOptions=[];selectedProductTitle=${encodeURIComponent(selectedFeedItem?.title)};selectedProductGroupId=${encodeURIComponent(selectedItemGroupId)};product[selectedProductGroupId][title]=selectedProductTitle"},"displayText":"Item selected"}}}`,
        true
      );
    };
    addItemToChatbotCart();
    closeModal();
  };

  return (
    <div css={modalContainerStyle} style={{ display: show ? "flex" : "none" }}>
      <div className="directorModalContent" css={modalContentStyle}>
        <CloseButton onClick={closeModal} />
        <NavigationButton direction="prev" onClick={onPrev} />
        <NavigationButton direction="next" onClick={onNext} />
        <FeedItemsRow
          feedItems={feedItems}
          selectedItemId={selectedItemId}
          selectFeedItem={selectFeedItem}
          feedItemsRowRef={feedItemsRowRef}
        />
        <SelectedFeedItem
          selectedFeedItem={selectedFeedItem}
          handleActionToParticipate={handleActionToParticipate}
          addItemToCart={addItemToCart}
          savedListAvailable={saveListAvailable}
          addItemToSavedList={addItemToSavedList}
          isAddedToSavedList={isAddedToSavedList}
          onOpenSavedList={handleOpenSavedList}
        />
      </div>
    </div>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button css={closeButtonStyle} onClick={onClick}>
    <img
      src="/odience/event/white-close.svg"
      alt="Close Catalogue"
      css={{ width: "1.25em" }}
    />
  </button>
);

const NavigationButton = ({
  direction,
  onClick,
}: {
  direction: "prev" | "next";
  onClick: () => void;
}) => (
  <button onClick={onClick} css={navButtonStyle(direction)}>
    <img
      src="/odience/user/sort_down.svg"
      alt={`${direction === "prev" ? "Previous" : "Next"} Item`}
      css={{
        transform: `rotate(${direction === "prev" ? 90 : 270}deg)`,
      }}
    />
  </button>
);

const FeedItemsRow = ({
  feedItems,
  selectedItemId,
  selectFeedItem,
  feedItemsRowRef,
}: {
  feedItems: FeedItemList;
  selectedItemId: string;
  selectFeedItem: (id: string) => void;
  feedItemsRowRef: React.RefObject<HTMLDivElement | null>;
}) => (
  <div css={feedItemsRowContainerStyle}>
    <div ref={feedItemsRowRef} css={feedItemsRowStyle}>
      {Object.entries(feedItems).map(([id, feedItem]) => (
        <button
          key={id}
          id={`miniFeed-${id}`}
          onClick={() => selectFeedItem(id)}
          css={feedItemButtonStyle(selectedItemId === id)}
        >
          <img
            src={feedItem.image_link}
            alt={feedItem.title}
            css={{ width: "6.25em", height: "6.25em", objectFit: "cover" }}
          />
        </button>
      ))}
    </div>
  </div>
);

const SelectedFeedItem = ({
  selectedFeedItem,
  handleActionToParticipate,
  addItemToCart,
  savedListAvailable,
  addItemToSavedList,
  onOpenSavedList,
  isAddedToSavedList,
}: {
  selectedFeedItem: FeedItem | null;
  handleActionToParticipate: () => void;
  addItemToCart: () => void;
  savedListAvailable: boolean;
  addItemToSavedList: () => void;
  onOpenSavedList: () => void;
  isAddedToSavedList: boolean;
}) => {
  const handleAddToListOrView = () => {
    if (isAddedToSavedList) {
      onOpenSavedList();
    } else {
      addItemToSavedList();
    }
  };

  const handleAddToCartOrView = () => {
    if (selectedFeedItem?.chatbot_id) {
      addItemToCart();
    } else if (selectedFeedItem?.link) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = selectedFeedItem.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const hasCheckoutOrProductDetail = !!(
    selectedFeedItem &&
    (selectedFeedItem.chatbot_id ? true : selectedFeedItem.link)
  );

  const hasSingleAction = !savedListAvailable || !hasCheckoutOrProductDetail;

  return (
    <div css={selectedFeedItemContainerStyle}>
      <div css={selectedFeedItemImageStyle}>
        <img
          src={selectedFeedItem?.image_link}
          alt={selectedFeedItem?.title}
          css={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div css={selectedFeedItemInfoColStyle}>
        <div css={selectedFeedItemNameStyle}>{selectedFeedItem?.title}</div>
        <div css={selectedFeedItemDescStyle}>
          {selectedFeedItem?.description}
        </div>
        <div css={selectedFeedItemButtonsRowStyle}>
          {!isProvisioned() ? (
            <button
              css={selectedFeedItemButtonStyle}
              onClick={handleActionToParticipate}
            >
              Log in to shop
            </button>
          ) : (
            <div
              css={{
                width: "100%",
                display: "flex",
                gap: "1em",
                alignItems: "center",
                justifyContent: hasSingleAction ? "right" : "center",
              }}
            >
              {savedListAvailable && (
                <button
                  onClick={handleAddToListOrView}
                  css={[addItemButtonStyle, { backgroundColor: "black" }]}
                >
                  {isAddedToSavedList ? (
                    <FeedActionImage
                      icon={"view_item_on_list.svg"}
                      text={"View List"}
                      alt={"View item on list"}
                    />
                  ) : (
                    <FeedActionImage
                      icon={"add_item_to_list.svg"}
                      text={"Save to List"}
                      alt={"Save item to list"}
                    />
                  )}
                </button>
              )}

              {hasCheckoutOrProductDetail && (
                <button
                  css={addItemButtonStyle}
                  onClick={handleAddToCartOrView}
                >
                  {selectedFeedItem.chatbot_id ? (
                    <FeedActionImage
                      icon={"add_to_cart.svg"}
                      text={"Add"}
                      alt={"Add item to cart"}
                    />
                  ) : (
                    <FeedActionImage
                      icon={"infos.svg"}
                      text={"Product details"}
                      alt={"Product details"}
                    />
                  )}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FeedActionImage = ({
  icon,
  text,
  alt,
}: {
  icon: string;
  text: string;
  alt: string;
}) => {
  return (
    <>
      <img
        src={`/odience/web_client/${icon}`}
        alt={alt}
        css={{ width: "1.6em", height: "1.6em" }}
      />
      {text}
    </>
  );
};

export default FeedItemsModal;

const modalContainerStyle = css({
  padding: "4vh",
  display: "flex",
  flexDirection: "column",
  zIndex: 9999,
  alignItems: "center",
  justifyContent: "center",
  transition: "0.3s ease",
  position: "fixed",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  overflow: "auto",
  backgroundColor: "rgb(0, 0, 0, 0.8)",
});

const modalContentStyle = css({
  position: "relative",
  width: "67vw",
  height: "75vh",
  backgroundColor: "#202020",
  borderRadius: "10px",
  maxHeight: "38.125em",
  maxWidth: "55em",
  display: "flex",
  flexDirection: "column",
});

const closeButtonStyle = css({
  position: "absolute",
  top: "-4em",
  right: "-4em",
  backgroundColor: "#1e1e1e",
  fontSize: "0.8rem",
  borderRadius: "50%",
  width: "3.5em",
  height: "3.5em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const navButtonStyle = (direction: "prev" | "next") =>
  css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.5rem",
    width: "2.5em",
    height: "2.5em",
    position: "absolute",
    top: "50%",
    [direction === "prev" ? "left" : "right"]: "-4em",
    cursor: "pointer",
    userSelect: "none",
    backgroundColor: "#1e1e1e",
    borderRadius: "50%",
  });

const feedItemsRowContainerStyle = css({
  padding: "0 2em",
  flex: "1 1 auto",
  overflow: "hidden",
});

const feedItemsRowStyle = css({
  display: "flex",
  gap: "0.6em",
  position: "relative",
  width: "100%",
  alignItems: "center",
  height: "fit-content",
  marginBottom: "0.6em",
  overflowX: "auto",
  overflowY: "hidden",
  padding: "1em 0",
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    backgroundColor: "#4a4a4a",
  },
  "&::-webkit-scrollbar": {
    height: "0.6rem",
    backgroundColor: "#000000",
    scrollbarColor: "#000000",
    scrollbarWidth: "thin",
  },
});

const feedItemButtonStyle = (selected: boolean) =>
  css({
    width: "6.25em",
    height: "6.25em",
    borderRadius: "10px",
    background: colors.primaryTextColor,
    overflow: "hidden",
    cursor: "pointer",
    flexShrink: 0,
    opacity: selected ? 1 : 0.6,
    transform: selected ? "scale(0.9)" : undefined,
    "&:hover": {
      opacity: 1,
      transform: "scale(0.9)",
    },
  });

const selectedFeedItemContainerStyle = css({
  color: colors.primaryTextColor,
  display: "flex",
  gap: "1.25em",
  height: "70%",
  width: "100%",
  justifyContent: "space-between",
  padding: "1em 2em",
  flex: "2 1 auto",
  overflow: "hidden",
});

const selectedFeedItemImageStyle = css({
  borderRadius: "10px",
  overflow: "hidden",
  flex: "1 1 40%",
});

const selectedFeedItemInfoColStyle = css({
  display: "flex",
  flexDirection: "column",
  gap: "0.6em",
  width: "70%",
});

const selectedFeedItemNameStyle = css({
  padding: "1em",
  fontSize: "1.25rem",
  fontWeight: "bold",
});

const selectedFeedItemDescStyle = css({
  padding: "1em",
  fontSize: "1.1rem",
  height: "18em",
  overflowY: "auto",
});

const selectedFeedItemButtonsRowStyle = css({
  display: "flex",
  gap: "0.6em",
  width: "100%",
});

const selectedFeedItemButtonStyle = css({
  width: "100%",
  fontSize: "1.25rem",
  height: "3em",
  boxSizing: "border-box",
  display: "flex",
  borderRadius: "10px",
  backgroundColor: colors.primaryAccentColor,
  cursor: "pointer",
  userSelect: "none",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5em",
  color: colors.primaryTextColor,
});

const addItemButtonStyle = css({
  width: "50%",
  fontSize: "1.25rem",
  height: "4em",
  boxSizing: "border-box",
  display: "flex",
  borderRadius: "10px",
  backgroundColor: colors.primaryAccentColor,
  cursor: "pointer",
  userSelect: "none",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid rgba(255, 255, 255, 0.3)",
  gap: "0.5em",
  color: colors.primaryTextColor,
  "&:disabled": {
    opacity: 0.5,
    pointerEvents: "none",
  },
});
